import './ButtonImage.css'
import PropTypes from "prop-types";

const ButtonImage = ({block, onClick}) => {
    let buttonProps = {};
    if (block.type !== 'disabled')
        buttonProps.onClick = onClick;
    return(
        <div id={'home-button-' + block.id} className={'homeButtonImage marginButtonImage ' + (block.type !== 'disabled' ? 'blockImageHover' : '')}>
            <div className='blockImage'
                 style={{color: block.textColor, backgroundImage: 'url("' + block.img + '")'}}
                 {...buttonProps}
            />
        </div>
    )
}

ButtonImage.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ButtonImage
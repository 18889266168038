import SearchBlock from "./SearchBlock";
import InfiniteScroll from "react-infinite-scroll-component";
import {connect} from "react-redux";

const Searches = ({infiniteScrollData, fetchData, hasMore, title, metaData, displayEndMessage, searchType, translations, board}) => {
    return (
        <>
            <InfiniteScroll
                dataLength={infiniteScrollData.length}
                next={fetchData}
                hasMore={hasMore}
                loader={
                    <div className='d-flex align-items-center justify-content-center mb-5 pb-5 pt-3'>
                        <i className='fa-4x fas fa-spinner fa-spin'/>
                    </div>
                }
                endMessage={
                    displayEndMessage
                        ?
                            <div className='d-flex align-items-center justify-content-center mb-5 pb-5 pt-3'>
                                <h2><i>{translations['EPB_E_POSTER_LIST_NO_MORE_E_POSTER']  ?? 'EPB_E_POSTER_LIST_NO_MORE_E_POSTER'}</i></h2>
                            </div>
                        : <></>
                }
            >
                {
                    infiniteScrollData.map(ePoster => {
                        return (
                            <div className='mb-5' key={ePoster.id}>
                                <SearchBlock ePoster={ePoster} searchBlockName={title} metaData={metaData} searchType={searchType} board={board} translations={translations} />
                            </div>
                        )
                    })
                }
            </InfiniteScroll>
        </>
    );
}

Searches.defaultProps = {
    filterEPostersPartial: [],
    fetchData: () => {},
    hasMore: false,
    title: '',
    metaData: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        defaultColors: [
            'lightblue',
            'grey',
            'black'
        ]
    },
    translations: []
}

const mapStateToProps = state => {
        return {
            translations: state.translation.translations
        }

};

export default connect(mapStateToProps, {})(Searches)

import {Modal} from "react-bootstrap";

const ModalLink = ({show, setShow, url}) => {
    return (
        <>
            <Modal show={show} onHide={() => setShow(false)} fullscreen={true}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <iframe src={url}
                            frameBorder="0"
                            style={{overflow: 'hidden', height: '100%', width:'100%'}}
                            height="100%"
                            width="100%"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                            title={url}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

ModalLink.defaultProps = {
    show: false
}

export default ModalLink
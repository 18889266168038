import Hr from "../../components/Hr";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import Navbar from "../../components/Navbar";
import {useEffect, useState} from "react";
import NoMatchFound from "../../components/NoMatchFound";
import Searches from "../../components/Searches";
import { INFINITE_SCROLL_LIMIT } from "../../config/common-configuration";
import {orderByDate, orderEposters} from '../../utils';

const SearchList = ({ePosters, metaData, translations, board}) => {
    const [displayEPosters, setDisplayEPosters] = useState([])
    const [filterEPostersPartial, setFilterEPostersPartial] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [displayEndMessage, setDisplayEndMessage] = useState(false)

    let { title, id, type, titleBlock } = useParams()

    useEffect( () => {
        const store = JSON.parse(localStorage.getItem('state'));

        setDisplayEndMessage(false)
        if (ePosters.length > 0) {
            const temp = ePosters.filter((ePoster) => {
                for(const filter of ePoster.metadata) {
                    if(filter.type === type && filter.id === parseInt(id)) {
                        return true
                    }
                }
                return false
            })
            let boardSearchTypeOrder = store.boards.board.searchTypeOrder;

            let orderedPosters = orderEposters(temp, boardSearchTypeOrder, store.block.searchBlock.searchType);

            setDisplayEPosters(orderedPosters)
            setFilterEPostersPartial(orderedPosters.slice(0, INFINITE_SCROLL_LIMIT));
            if(orderedPosters.length <= INFINITE_SCROLL_LIMIT) {
                setHasMore(false)
            } else {
                setHasMore(true)
            }

            if(orderedPosters.length > 3) {
                setDisplayEndMessage(true)
            }

        } else {
            setHasMore(false)
            setFilterEPostersPartial([]);
        }

    }, [ePosters, id, type])

    const fetchData = () => {
            let temp = filterEPostersPartial
            let temp2 = displayEPosters.slice(filterEPostersPartial.length, filterEPostersPartial.length + INFINITE_SCROLL_LIMIT)
            temp = temp.concat(temp2)
            setFilterEPostersPartial(temp);
            if(temp.length === displayEPosters.length) {
                setHasMore(false)
            }
    }

    return (
        <>
            <Navbar title={titleBlock} metaData={metaData}/>
            <div className='d-flex flex-column justify-content-center pt-4 align-items-center'>
                <h1>{decodeURIComponent(title).replace(/<[^>]+>/g, ' ')}</h1>
            </div>
            <div className='pt-3 me-5 ms-5'>
                <Hr />
            </div>
            <div className='mt-5 ms-4'>
                <Searches metaData={metaData}
                          fetchData={() => fetchData()}
                          hasMore={hasMore}
                          title={title}
                          board={board}
                          infiniteScrollData={filterEPostersPartial}
                          displayEndMessage={displayEndMessage}/>
            </div>

            <div style={{display: (displayEPosters.length > 0) ? 'none' : 'block'}}>
                <NoMatchFound text={translations['EPB_E_POSTER_LIST_NO_MATCH'] ?? 'EPB_E_POSTER_LIST_NO_MATCH'} />
            </div>
        </>
    )
}

SearchList.defaultProps = {
    ePosters: [],
    metaData: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        defaultColors: [
            'lightblue',
            'grey',
            'black'
        ]
    },
    translations: {}
}

const mapStateToProps = (state, ownProps) => {
    return {
        ePosters: state.ePoster.ePosters,
        translations: state.translation.translations
    }
};

export default connect(mapStateToProps, {})(SearchList)
import React, {useEffect, useState} from "react";
import './Carousel.css'
import SwiperCore, {Navigation} from 'swiper/core';
import Slider from "react-slick";
import {TIME_TO_UPDATE} from "../env";

// install Swiper modules
SwiperCore.use([Navigation]);

const Carousel = ({medias, setSliderCarousel, settings, serverLocalUrl, display, addMediaVideoView, displayType}) => {
    const [slider, setSlider] = useState();
    const [duration, setDuration] = useState(0);
    const [mediaId, setMediaId] = useState(0);
    const [intervalToIncreaseVisionTime, setIntervalToIncreaseVisionTime] = useState(null);
    const [mediaErrors, setMediaErrors] = useState([]);
    const [didMount, setDidMount] = useState(true);

    useEffect(() => {
        if(!didMount) {
            setSliderCarousel(slider)
        }
        setDidMount(false)
    }, [setSliderCarousel, slider, didMount]);

    useEffect(() => {
        if (duration === TIME_TO_UPDATE) {
            addMediaVideoView(mediaId, duration);
            setDuration(0);
        }
    }, [addMediaVideoView, duration, mediaId]);

    const handleOnPlay = (media) => {
        clearInterval(intervalToIncreaseVisionTime);
        setMediaId(media.id);

        setIntervalToIncreaseVisionTime(setInterval(() => {
            setDuration((t) => t + 1);
        }, 1000));
    };

    const handleOnPause = (media) => {
        clearInterval(intervalToIncreaseVisionTime);

        if (duration !== 0) {
            addMediaVideoView(media.id, duration);
            setDuration(0);
        }
    };

    const handleOnEnded = (media) => {
        clearInterval(intervalToIncreaseVisionTime);

        if (duration !== 0) {
            addMediaVideoView(media.id, duration);
            setDuration(0);
        }
    };

    const handleErrorMedia = (event, index, media) => {
        if(
            !mediaErrors.includes(index)
            && media.urlLocal
            && media.urlLocal.trim() !== ''
        ) {
            let temp = [...mediaErrors]
            temp.push(index)
            setMediaErrors(temp)
            let mediaId = null
            switch(media.type) {
                case 'img':
                    mediaId = document.getElementById('media-img-' + displayType  + index)
                    break;
                case 'video':
                    mediaId = document.getElementById('e-poster-video-' + displayType + index)
                    break;
                default:
                    break;
            }
            if(mediaId && mediaId.src !== mediaId.urlDistant) {
                mediaId.src = media.urlDistant
            }

        }
    }

    const renderSwitch = (media, i) => {
        switch (media.type) {
            case 'img':
                return <img id={'media-img-' + displayType + i}
                            style={{width: '100%'}}
                            alt={'media-img-' + i}
                            className='img-fluid img-to-print'
                            src={(
                                !serverLocalUrl
                                || serverLocalUrl.trim() === ''
                                || media.urlLocal === ''
                            ) ? media.urlDistant : media.urlLocal}
                            onError={(e) => handleErrorMedia(e, i, media)}
                    />;
            case 'video':
                return (
                    <div className={'fullScreenOff'}>
                        <video id={'e-poster-video-' + displayType + i}
                               controls
                               style={{width: '100%', height: ((display === 'landscape') ? '90vh' : '40vh')}}
                               poster=""
                               controlsList='nodownload'
                               disablePictureInPicture={true}
                               onPlay={() => handleOnPlay(media)}
                               onPause={() => handleOnPause(media)}
                               onEnded={() => handleOnEnded(media)}
                               onError={(e) => handleErrorMedia(e, i, media)}
                        >
                            <source
                                src={(
                                    !serverLocalUrl
                                    || serverLocalUrl.trim() === ''
                                    || media.urlLocal === ''
                                ) ? media.urlDistant : media.urlLocal}
                            />
                            To view this video please enable JavaScript, and consider upgrading to a web browser that
                            supports HTML5 video
                        </video>
                    </div>
                )
            default:
                return <></>;
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-12' style={{backgroundColor: 'transparent'}}>
                    <div>
                        <Slider ref={c => (setSlider(c))} {...settings}
                                navigation={true}
                                className="mySwiper"
                        >
                            {medias.map((media, i) => (
                                <div key={i}>{renderSwitch(media, i)}</div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

Carousel.defaultProps = {
    medias: [],
    indexCurrentSlide: 0,
    settings: {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        arrows: true,
    }
}


export default Carousel;

import SearchFilter from "../../components/SearchFilter";
import './SearchAlphabetical.css'
import Navbar from "../../components/Navbar";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getFilterType} from "../../redux/actions/filterAction";
import {getBlock} from "../../redux/actions/blockAction";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMatchFound from "../../components/NoMatchFound";
import { INFINITE_SCROLL_LIMIT } from "../../config/common-configuration";

const SearchAlphabetical = ({ePosters, block, translations, onClickAlphabetical, getBlock, getFilterType, metaData}) => {
    const [filterEPosters, setFilterEPosters] = useState([])
    const [filterEPostersPartial, setFilterEPostersPartial] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [useOnce, setUseOnce] = useState(true);
    const [displayEndMessage, setDisplayEndMessage] = useState(false)

    let { id } = useParams()

    useEffect( () => {
        if(useOnce) {
            // if search block empty we call the api to feed it
            if(block.id === 0 || block.id !== parseInt(id)) {
                getBlock(id)
            }
            getFilterType(id)
            setUseOnce(false)
        }
    })

    useEffect( () => {
        setFilterEPosters(ePosters)
        setFilterEPostersPartial(ePosters.slice(0, INFINITE_SCROLL_LIMIT));
    }, [ePosters])

    const onHandleFilter = (text, letters) => {
        setDisplayEndMessage(false)
        const temp = (ePosters.filter((ePoster) => {
            if(ePoster.id === undefined) {
                return true
            } else {
                if(!ePoster.name.toUpperCase().includes(text.toUpperCase())) {
                    return false
                }
                if(letters.length > 0) {
                    for(const letter of letters) {
                        if(ePoster.name[0].toUpperCase() === letter) {
                            return true
                        }
                    }

                    return false
                } else {
                    return true
                }
            }
        }))

        //Remove useless letters
        const temp2 = (temp.filter((ePoster, i) => {
            if(ePoster.id === undefined) {
                if ((temp.length - 1) < (i + 1) || temp[(i + 1)].id === undefined) {
                    return false
                }
            }

            return true
        }))

        setFilterEPosters(temp2)
        setFilterEPostersPartial(temp2.slice(0, INFINITE_SCROLL_LIMIT))
        if(temp2.length <= INFINITE_SCROLL_LIMIT) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }

        if(temp2.length > 3) {
            setDisplayEndMessage(true)
        }
    }

    const fetchData = () => {
            let temp = filterEPostersPartial
            let temp2 = filterEPosters.slice(filterEPostersPartial.length, filterEPostersPartial.length + INFINITE_SCROLL_LIMIT)
            temp = temp.concat(temp2)
            setFilterEPostersPartial(temp);
            if(temp.length === filterEPosters.length) {
                setHasMore(false)
            }
    }

    return (
        <>
            <Navbar title={block.name} metaData={metaData}/>
            <SearchFilter block={block}
                          placeholder={translations['EPB_SEARCH_TITLE_' + block.searchType.toUpperCase()] ?? 'EPB_SEARCH_TITLE_' + block.searchType.toUpperCase()}
                          onChangeText={(text, letters) => onHandleFilter(text, letters)} />
            <div className='mt-4 ms-5 listSection'>
                <InfiniteScroll
                    dataLength={filterEPostersPartial.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={
                        <div className='d-flex align-items-center justify-content-center mb-5 pb-5 pt-3'>
                            <i className='fa-3x fas fa-spinner fa-spin'/>
                        </div>
                    }
                    endMessage={
                        displayEndMessage
                            ?
                            <div className='d-flex align-items-center justify-content-center mb-5 pb-5 mt-5 pt-3'>
                                <h2><i>{translations['EPB_E_POSTER_LIST_NO_MORE_AUTHOR']  ?? 'EPB_E_POSTER_LIST_NO_MORE_AUTHOR'}</i></h2>
                            </div>
                            : <></>
                    }
                >
                    {
                        filterEPostersPartial.map((ePoster, i) => {
                            if(ePoster.letter) {
                                return (
                                    <div className='mb-2' key={'letter-' + i}><strong>{ePoster.letter}</strong></div>
                                )
                            } else {
                                return (
                                    <div onClick={() => onClickAlphabetical(ePoster, block.name)} className='mb-2 linkAuthor' key={'author-' + ePoster.id}>
                                        <span className='authorName'>{ePoster.lastname}</span>
                                        &nbsp;
                                        <span className='authorSurname'>{ePoster.firstname}</span>
                                    </div>
                                )
                            }
                        })
                    }
                </InfiniteScroll>
                <div style={{display: (filterEPosters.length > 0) ? 'none' : 'block'}}>
                    <NoMatchFound text={translations['EPB_E_POSTER_LIST_NO_MATCH']  ?? 'EPB_E_POSTER_LIST_NO_MATCH'}/>
                </div>
            </div>
        </>
    )
}

SearchAlphabetical.defaultProps = {
    block: {
        id: 0,
        name: '',
        size: 'small',
        type: 'search',
        searchType: '',
        img:  null,
        icon:  'fas fa-door-open',
        textColor: '#2BB85A',
        bgColor: '#2BB85A',
        displayMode: 'research',
        title: '',
    },
    ePosters: [],
    translations: {}
}

const mapStateToProps = (state, ownProps) => {
    if(state.block && state.block.searchBlock && state.block.searchBlock.id !== 0) {
        return {
            block: state.block.searchBlock,
            ePosters: state.filter.alphabeticalList,
            translations: state.translation.translations
        }
    } else {
        return {
            ...ownProps,
            ePosters: state.filter.alphabeticalList,
            translations: state.translation.translations
        }
    }
};

export default connect(mapStateToProps, {getFilterType, getBlock})(SearchAlphabetical)
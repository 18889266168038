import {useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import './Preview.css'
import {previewAuthenticate, resetPreviewRedirect} from "../../controller/authenticateController";

const Preview = ({previewAuthenticate, isRedirectPreview, resetPreviewRedirect}) => {
    const history = useHistory();
    const didMount = useRef(false);
    useEffect(() => {
        if(didMount.current) {
            if(isRedirectPreview) {
                history.push('/home')
            }
        }
    }, [history, isRedirectPreview])

    useEffect(() => {
        if(!didMount.current) {
            resetPreviewRedirect()
            previewAuthenticate(history.location.search)

        }
    }, [history.location.search, previewAuthenticate, resetPreviewRedirect])

    useEffect(() => {
        didMount.current = true
    })

    return (
        <>
            <div className='d-flex align-items-center justify-content-center' style={{height: '40vh'}}>
                <i className='fa-4x fas fa-spinner fa-spin'/>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        isRedirectPreview: state.authenticate.isRedirectPreview
    }
};

export default connect(mapStateToProps, {previewAuthenticate, resetPreviewRedirect})(Preview)

import {GET_BOARD, GET_BOARD_LIST, GET_CSS} from '../types';
import {call} from '../api';
import { getConfig } from './configAction';
import LanguageConfig from '../../configuration/language.js'
import {addMail} from "./ePosterAction";

export const getBoardList = () => async (dispatch, getState) => {
    const state = getState();
    const url = state.authenticate.url + 'api/eposter-board/list';
    let response = await (
        await call(
            'GET',
            url,
            {},
            {},
            state,
            GET_BOARD_LIST
        )
    )(dispatch);

    if (response.data !== undefined) {
        dispatch({
            type: GET_BOARD_LIST,
            payload:
                {
                    "count": response.data.count,
                    "data": response.data.boardList
                }
        })
    }
};

export const getBoard = (boardId, isPreview = false) => async (dispatch, getState) => {
    const state = getState();
    const url = state.authenticate.url + 'api/eposter-board/' + boardId;
    let response = await (
        await call(
            'GET',
            url,
            {},
            {},
            state,
            GET_BOARD
        )
    )(dispatch);

    if (response.data !== undefined) {
        dispatch({
            type: GET_BOARD,
            payload: {
                id: response.data.id,
                catId: response.data.catId,
                name: response.data.name,
                display: response.data.displayType,
                bannerUrl: response.data.bannerUrl,
                primaryColor: response.data.primaryColor,
                secondaryColor: response.data.secondaryColor,
                ternaryColor: response.data.ternaryColor,
                isComment: !!response.data.isCommentEnabled,
                isLanguageSelector: response.data.isLangueSelectorEnabled,
                isPrintingEnabled: response.data.isPrintingEnabled,
                languageId: response.data.langueId,
                languageSelectorIds: response.data.selectorLangueId ? response.data.selectorLangueId.split('|') : [],
                isRating: !!response.data.isRatingEnabled,
                serverLocalUrl: response.data.localServerUrl,
                columnGrid: response.data.displayType === 'landscape' ? 5 : 2,
                searchTypeOrder: response.data.eposterBoardSearchCollection,
                isAutoBackupEnabled: response.data.eposterEnableAutoHpBackup,
                inactivityDurationTime: response.data.inactivityDurationTime,
                isSendingMail: response.data.isMailingEnabled,
                mailSender:response.data.mailSender,
                mailSenderMessage:response.data.mailSenderMessage,
            }
        })

        const languageData = LanguageConfig.languages.filter((language) => language.id  === response.data.langueId);

        if(languageData && languageData.length > 0) {
            dispatch(getConfig(true, languageData[0]));
        }

        if(response.data.css !== undefined) {
            dispatch({
                type: GET_CSS,
                payload: response.data.css
            })
        }

        return true
    } else {
        return false
    }
};

import './ButtonText.css'
import PropTypes from "prop-types";

const ButtonText = ({block, onClick}) => {
    let buttonProps = {};
    if (block.type !== 'disabled')
        buttonProps.onClick = onClick;
    return(
        <div id={'home-button-' + block.id} className={'marginButtonText homeButtonText'}>
            <div
                {...buttonProps}
               className={block.type !== 'disabled' ? 'blockText' : 'blockTextDisabled'}
               style={{color: block.textColor, backgroundColor: block.bgColor}} >
                <div className='d-flex flex-column'
                     style={{height: '100%'}}>
                    <div className='mt-3 mb-auto'>
                        <strong style={{color: block.textColor}}
                                className={'' + (block.size === 'big' ? 'titleBig' : 'titleSmall') + ' p-1'}
                                dangerouslySetInnerHTML={{__html: block.name}}
                        />
                    </div>
                    <div className={'mb-3'}
                         style={{color: block.textColor}} >
                        <i className={(block.size === 'big' ? 'faBigDynamic ' : 'faSmallDynamic ') + block.icon}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

ButtonText.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ButtonText
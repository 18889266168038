import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"

import store from "./redux/store";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from 'react-router-dom'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <Router>
                <App />
          </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

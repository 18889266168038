import {Modal} from "react-bootstrap";
import Carousel from "./Carousel";
import {connect} from "react-redux";

const ModalEPoster = ({
                          show,
                          setShow,
                          medias,
                          serverLocalUrl,
                          translations,
                          setSliderCarousel,
                          display,
                          settings,
                          addMediaVideoView,
}) => {

    return (
        <>
            <Modal className="eposter-modal" show={show} onHide={() => setShow(false)} fullscreen={true}>
                <Modal.Header className="eposter-modal__header" closeButton>
                    <Modal.Title className="eposter-modal__header-title">{translations['EPB_FULLSCREEN_TITLE']  ?? 'EPB_FULLSCREEN_TITLE'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="eposter-modal__body">
                    <Carousel medias={medias}
                              setSliderCarousel={(s) => setSliderCarousel(s)}
                              settings={settings}
                              serverLocalUrl={serverLocalUrl}
                              display={display}
                              addMediaVideoView={addMediaVideoView}
                              displayType='modal'
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

ModalEPoster.defaultProps = {
    show: false,
}

const mapStateToProps = state => {
    return {
        translations: state.translation.translations
    }
};

export default connect(mapStateToProps, {})(ModalEPoster)
import './Boards.css'
import {useEffect, useState} from 'react'
import {getBoardList} from '../../redux/actions/boardAction'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {Card} from "react-bootstrap";

const Boards = ({getBoardList, boards, onClick}) => {
    const [boardSelected, setBoardSelected] = useState(0)

    useEffect(() => {
        getBoardList()
    }, [getBoardList])

    const handleClick = (idBoard) => {
        setBoardSelected(idBoard)
    }

    return (
        <div style={{width: '75%', margin: 'auto'}}>
            <h3>Board selection:</h3>
            <div className='m-5'>
                {boards && boards.map((board) => (
                    <Card className={'mt-5 cardBoard' + ((boardSelected === board.id) ? ' selected' : '')}
                          key={board.id}
                          onClick={() => handleClick(board.id)}
                    >
                        <Card.Img variant="top" src={board.bannerUrl}/>
                        <Card.Body>
                            <Card.Text>
                                <strong>{board.name}</strong>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                ))}
            </div>
            <div className='d-flex justify-content-end mt-4'>
                <button
                    type='button'
                    className="btn btn-secondary me-3"
                    onClick={() => onClick(1)}
                >
                    Previous
                </button>
                <button type="button"
                        className="btn btn-primary"
                        disabled={boardSelected === 0}
                        onClick={() => onClick(2, boardSelected)}
                >
                    Next
                </button>
            </div>
        </div>
    )
}

Boards.propTypes = {
    getBoardList: PropTypes.func.isRequired,
    boards: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
    return ({
        boards: state.boards.boards,
    })
};

export default connect(mapStateToProps, {getBoardList})(Boards)
import './Hr.css'

const Hr = () => {

    return (
        <>
            <div className='hrStyle' />
        </>
    );
}

export default Hr;

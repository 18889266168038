import PropTypes from "prop-types";
import Authentication from './Authentication';
import Steps from '../../components/Steps';
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {authenticated, authentication, setResetAuthenticateStatus} from "../../redux/actions/authenticateAction";
import {getBoard} from "../../redux/actions/boardAction";
import Boards from "./Boards";
import store from '../../redux/store';
import {useHistory} from "react-router-dom";
import {getConfig} from "../../redux/actions/configAction";
import {resetAuthenticateError} from "../../controller/authenticateController";

const Config = ({authentication, authenticated, nbStep, authenticateStatus, getBoard, getConfig, resetAuthenticateError, errors, setResetAuthenticateStatus}) => {
    const [invalidInputApiUrl, setInvalidInputApiUrl] = useState('')
    const [invalidInputToken, setInvalidInputToken] = useState('')
    const [invalidAuthentication, setInvalidAuthentication] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)
    const [didMount, setDidMount] = useState(false);
    const state = store.getState();
    const history = useHistory();

    useEffect( () => {
        if(didMount) {
            switch(authenticateStatus) {
                case 1:
                    authenticated()
                    setCurrentStep(2)
                    getConfig()
                    break;
                case 2:
                    setInvalidAuthentication(true)
                    break;
                default:
                    setInvalidAuthentication(false)
            }
        } else {
            resetAuthenticateError()
            setDidMount(true)
        }

    }, [authenticateStatus, authenticated, getConfig, didMount, resetAuthenticateError])

    // submit from Authentication component
    const handleSubmit = (e, apiUrl, token) => {
        e.preventDefault()
        setInvalidAuthentication(false)
        setResetAuthenticateStatus();

        if (!apiUrl) {
            setInvalidInputApiUrl(' is-invalid')
            return
        }
        setInvalidInputApiUrl('')
        if (!token) {
            setInvalidInputToken(' is-invalid')
            return
        }
        setInvalidInputToken('')
        authentication(apiUrl, token)
    }

    // Selecting board
    const handleClick = async (type, boardId) => {
        switch(type) {
            case 1:
                setCurrentStep(1)
                break
            case 2:
                await getBoard(boardId)
                window.scrollTo(0, 0);
                history.push('/')
                break
            default:
        }
    }

    return (
        <div className='p-5'>
            <div className="d-flex justify-content-center mb-5">
                <h1>Configuration:</h1>
            </div>
            {
                currentStep === 1 ? (
                    <Authentication
                        onSubmit={(e, apiUrl, token) => handleSubmit(e, apiUrl, token)}
                        invalidAuthentication={invalidAuthentication}
                        invalidInputApiUrl={invalidInputApiUrl}
                        invalidInputToken={invalidInputToken}
                        tokenInit={state.authenticate.grantAccessToken}
                        urlInit={state.authenticate.url}
                        errors={errors}
                    />
                ) : (
                    <Boards onClick={(type, boardId) => handleClick(type, boardId)} />
                )
            }
            <Steps nbStep={nbStep} currentStep={currentStep} />
        </div>
    );
}

Config.defaultProps = {
    nbStep: 2,
    authenticateStatus: 0,
}

Config.propTypes = {
    nbStep: PropTypes.number,
    authenticateStatus: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
    if(state.authenticate.authenticateStatus !== 0) {
        return {
            authenticateStatus: state.authenticate.isAuthenticate,
            errors: state.authenticate.errors,
        }
    } else {
        return ownProps
    }
};

export default connect(mapStateToProps, {authentication, authenticated, getBoard, getConfig, resetAuthenticateError, setResetAuthenticateStatus})(Config)

import {GET_CONFIG, GET_TRANSLATION} from '../types';
import {call} from '../api';

export const getConfig = (_languageOnly = false, language = undefined) => async (dispatch, getState) => {
    const state = getState();
    const url = state.authenticate.url + 'api/config'

    let bodyFormData = new FormData()
    if (language) {
        bodyFormData.append('langId', language.id + '')
    } else {
        bodyFormData.append('langId', state.translation.currentLanguage.id + '')
    }

    let response = await (
        await call(
            'POST',
            url,
            {},
            bodyFormData,
            state,
            GET_CONFIG
        )
    )(dispatch);

    if (response.data !== undefined && response.data.translation !== undefined) {
        dispatch({
            type: GET_TRANSLATION,
            payload: {
                translations: translateRemoveUriEncode(response.data.translation),
                currentLanguage: language
            }
        })

        return true
    } else {
        return false
    }
};

const translateRemoveUriEncode = (data) => {
    if(data) {
        for (const [key, value] of Object.entries(data)) {
            data[key] = value.toString() === '' ? undefined : value.toString()
            if(data[key]) {
                data[key] = decodeURIComponent(unescape(value.toString()))
            }
        }
    }

    return data
}

import SearchFilter from "../../components/SearchFilter";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import Navbar from "../../components/Navbar";
import {useParams} from "react-router-dom";
import {getBlock} from "../../redux/actions/blockAction";
import NoMatchFound from "../../components/NoMatchFound";
import SearchSessions from "../../components/SearchSessions";
import { INFINITE_SCROLL_LIMIT } from "../../config/common-configuration";

const SearchResearch = ({block, ePosters, getBlock, metaData, translations, onSessionClick}) => {
    let { id } = useParams()

    const [filterEPosters, setFilterEPosters] = useState([])
    const [filterEPostersPartial, setFilterEPostersPartial] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [displayEndMessage, setDisplayEndMessage] = useState(false)
    const [useOnce, setUseOnce] = useState(true);

    useEffect( () => {
        if(useOnce) {
            // if block empty we call the api to feed it
            if(block.id === 0 || block.id !== parseInt(id)) {
                getBlock(id)
            }
            setUseOnce(false)
        }
    }, [useOnce, block.id, id, getBlock])
    useEffect( () => {
        setFilterEPosters(ePosters)
        if(ePosters) {
            setFilterEPostersPartial(ePosters.slice(0, INFINITE_SCROLL_LIMIT));
        }
    }, [ePosters])

    const onHandleFilter = (text, letters) => {
        setDisplayEndMessage(false)
        const temp = (ePosters.filter((ePoster) => {
            if(!ePoster[block.searchType].toUpperCase().includes(text.toUpperCase())) {
                return false
            }
            if(letters.length > 0) {
                for(const letter of letters) {
                    if(ePoster[block.searchType][0].toUpperCase() === letter) {
                        return true
                    }
                }
                return false
            } else {
                return true
            }
        }))
        setFilterEPosters(temp)
        setFilterEPostersPartial(temp.slice(0, INFINITE_SCROLL_LIMIT));
        if(temp.length <= INFINITE_SCROLL_LIMIT) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }

        if(temp.length > 3) {
            setDisplayEndMessage(true)
        }
    }

    const nextData = () => {
        let temp = filterEPostersPartial
        let temp2 = filterEPosters.slice(filterEPostersPartial.length, filterEPostersPartial.length + INFINITE_SCROLL_LIMIT)
        temp = temp.concat(temp2)
        setFilterEPostersPartial(temp);
        if(temp.length === filterEPosters.length) {
            setHasMore(false)
        }
        if(temp.length <= INFINITE_SCROLL_LIMIT) {
            setHasMore(false)
        }
    }

    switch (block.searchType) {
        case 'session_title':
            filterEPostersPartial.sort((a, b) => a.session_title.localeCompare(b.session_title, undefined, { sensitivity: 'base' }));
            break;
        case 'session_reference':
            filterEPostersPartial.sort((a, b) => a.session_reference.localeCompare(b.session_reference, undefined, { sensitivity: 'base' }));
            break;
        default:
            //Do nothing
            break;
    }
    return (
        <div className="searchResearch">
            <Navbar title={block.name} metaData={metaData} />
            <SearchFilter block={block}
                          placeholder={translations['EPB_SEARCH_TITLE_' + block.searchType.toUpperCase()] ?? 'EPB_SEARCH_TITLE_' + block.searchType.toUpperCase()}
                          onChangeText={(text, letters) => onHandleFilter(text, letters)} />
            <div className='mt-4'>
                <SearchSessions
                    metaData={metaData}
                    fetchData={() => nextData()}
                    hasMore={hasMore}
                    title={block.name}
                    infiniteScrollData={filterEPostersPartial}
                    displayEndMessage={displayEndMessage}
                    searchType={block.searchType}
                    onSessionClick={onSessionClick}
                />
                <div style={{display: (filterEPosters.length > 0) ? 'none' : 'block'}}>
                    <NoMatchFound text={translations['EPB_E_POSTER_LIST_NO_MATCH']  ?? 'EPB_E_POSTER_LIST_NO_MATCH'} />
                </div>
            </div>
        </div>
    )
}

SearchResearch.defaultProps = {
    block: {
        id: 0,
        name: '',
        size: 'small',
        type: 'search',
        searchType: '',
        img:  null,
        icon:  'fas fa-door-open',
        textColor: '#2BB85A',
        bgColor: '#2BB85A',
        displayMode: 'research',
        title: '',
    },
    ePosters: [],
    metaData: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        defaultColors: [
            'lightblue',
            'grey',
            'black'
        ]
    },
}

const mapStateToProps = (state, ownProps) => {

    const ePosterUniqueSessions = [...new Map(state.ePoster.ePosters.map(item =>
        [item['session_reference'], item])).values()];
      ;
    
    if(state.block && state.block.searchBlock && state.block.searchBlock.id !== 0) {
        return {
            block: state.block.searchBlock,
            ePosters: ePosterUniqueSessions,
            translations: state.translation.translations

        }
    } else {
        return {
            ...ownProps,
            ePosters: ePosterUniqueSessions,
            translations: state.translation.translations
        }
    }
};

export default connect(mapStateToProps, {getBlock})(SearchResearch)

import {GET_BOARD, GET_BOARD_LIST} from '../types';

const initialState = {
    board: {
        id: 0,
        catId: 0,
        name: '',
        display: '',
        bannerUrl: '',
        primaryColor: '',
        secondaryColor: '',
        ternaryColor: '',
        isComment: false,
        isRating: false,
        isLanguageSelector: false,
        languageId: '2',
        languageSelectorIds: [],
        serverLocalUrl: '',
        columnGrid: 0
    },
    boards: [],
    countBoards: 0
};

export default function foo(state = initialState, action) {
    switch (action.type) {
        case GET_BOARD_LIST:
            return {
                ...state,
                boards: action.payload.data,
                countBoards: action.payload.count,
            }
        case GET_BOARD:
            return {
                ...state,
                board: action.payload,
            }
        default:
            return state;
    }
}
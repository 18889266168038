import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import Hr from "../../components/Hr";
import Navbar from "../../components/Navbar"
import SearchBlock from "../../components/SearchBlock";
import { INFINITE_SCROLL_LIMIT } from "../../config/common-configuration";
import { enumSearchType } from "../../config/search-configuration";
import "./SearchInterventions.css"
import {orderEposters} from '../../utils';

const SearchInterventions = ({board, metaData}) => {
    let { sessionRef } = useParams();
    let { blockTitle } = useParams();
    const history = useHistory();

    const {interventions, translations, blockName} = useSelector((store) => {
        let filteredPosters = store.ePoster.ePosters.filter(ePoster => ePoster.session_reference === sessionRef)
        let boardSearchTypeOrder = store.boards.board.searchTypeOrder;
        filteredPosters = orderEposters(filteredPosters, boardSearchTypeOrder, store.block.searchBlock.searchType)

        return {
            interventions: filteredPosters,
            translations: store.translation.translations,
            blockName: store.block.searchBlock.name === '' ?  blockTitle : store.block.searchBlock.name
        }
    })

    const [countDisplayed, setCountDisplayed] = React.useState(INFINITE_SCROLL_LIMIT);
    const [hasMore, setHasMore] = React.useState(interventions.length > INFINITE_SCROLL_LIMIT);
    const [displayEndMessage, setDisplayEndMessage] = React.useState(interventions.length <= INFINITE_SCROLL_LIMIT);

    const nextData = () => {
        if ((countDisplayed + INFINITE_SCROLL_LIMIT) >= interventions.length) {
            setCountDisplayed(interventions.length)
            setHasMore(false)
            setDisplayEndMessage(true)
        } else {
            setCountDisplayed(countDisplayed + INFINITE_SCROLL_LIMIT)
        }
    }

    return (
        <div className="searchIntervention">
            <Navbar title={blockName} metaData={metaData}/>
            <div className='d-flex flex-column justify-content-center pt-4 align-items-center'>
                <h1>{interventions[0].session_title}</h1>
            </div>
            <div className='d-flex flex-column align-items-end me-5'>
                <div
                    className="search-interventions_previous py-1 px-3"
                    onClick={history.goBack}
                    style={{backgroundColor: metaData.defaultColors[0], color: metaData.defaultColors[2]}}>
                    <i id='navbar-previous-icon' className='fas fa-arrow-left me-3' />
                    <strong className='ms-2' id='navbar-previous-text'>{translations['EPB_SESSION_PREVIOUS']  ?? 'EPB_SESSION_PREVIOUS'}</strong>
                </div>
            </div>
            <div className='pt-3 me-5 ms-5'>
                <Hr />
            </div>
            <div className="mt-5 mx-4">
                <InfiniteScroll
                    dataLength={countDisplayed}
                    next={nextData}
                    hasMore={hasMore}
                    loader={
                        <div className='d-flex align-items-center justify-content-center mb-5 pb-5 pt-3'>
                            <i className='fa-4x fas fa-spinner fa-spin'/>
                        </div>
                    }
                    endMessage={
                        displayEndMessage
                            ?
                                <div className='d-flex align-items-center justify-content-center mb-5 pb-5 pt-3'>
                                    <h2><i>{translations['EPB_E_POSTER_LIST_NO_MORE_E_POSTER']  ?? 'EPB_E_POSTER_LIST_NO_MORE_E_POSTER'}</i></h2>
                                </div>
                            : <></>
                    }
                >
                    {
                        interventions.slice(0, countDisplayed).map(ePoster => {
                            return (
                                <div className='mb-5' key={ePoster.id}>
                                    <SearchBlock
                                        ePoster={ePoster}
                                        searchBlockName={blockName}
                                        metaData={metaData}
                                        translations={translations}
                                        board={board}
                                        searchType={enumSearchType.INTERVENTION} />
                                </div>
                            )
                        })
                    }
                </InfiniteScroll>
            </div>
        </div>
    )
}


export default SearchInterventions

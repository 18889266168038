import {authentication} from "../redux/actions/authenticateAction";
import {ERROR_DEFAULT} from "../redux/types";
import {getConfig} from "../redux/actions/configAction";
import {getBoard} from "../redux/actions/boardAction";


export const previewAuthenticate = (searchQuery) => dispatch => {
    dispatch({
        type: 'AUTHENTICATE_PREVIEW_REDIRECT',
        payload: false,
    })
    const params = new URLSearchParams(searchQuery)
    const url = params.get('url')
    const token = params.get('token')
    const boardId = params.get('boardId')
    if(url === null || token === null || boardId === null) {
        dispatch({
            type: ERROR_DEFAULT,
            payload: {
                code: 403,
                text: 'Can\'t authenticate. Wrong connection set.',
            },
        });
        return false;
    }
    dispatch({
        type: 'CLEAR_STORE',
        payload: {},
    });
    localStorage.clear()
    dispatch(authentication(url, token)).then((isAuthenticate) => {
        if(isAuthenticate) {
            dispatch(getConfig()).then((isSuccess) => {
                if(isSuccess) {
                    dispatch(getBoard(boardId)).then((isSuccess) => {
                        if(isSuccess) {
                            dispatch({
                                type: 'AUTHENTICATE_PREVIEW_REDIRECT',
                                payload: true,
                            });
                        }
                    })
                }
            })
        } else {
            dispatch({
                type: ERROR_DEFAULT,
                payload: {
                    code: 403,
                    text: 'Can\'t authenticate. Wrong connection set.',
                },
            });
        }
    })
}
export const resetPreviewRedirect = () => dispatch => {
    dispatch({
        type: 'AUTHENTICATE_PREVIEW_REDIRECT',
        payload: false,
    })
}

export const resetAuthenticateError = () => dispatch => {
    dispatch({
        type: 'RESET_AUTHENTICATE_ERROR',
        payload: {},
    });
}
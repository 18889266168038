import './SearchGrid.css'
import BorderIcon from "../../components/BorderIcon";
import SearchGridRow from './SearchGridRow'
import Navbar from "../../components/Navbar";
import {connect} from "react-redux";
import {getBlock} from "../../redux/actions/blockAction";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getFilterType} from "../../redux/actions/filterAction";
import {useMediaQuery} from "react-responsive";

const SearchGrid = ({block, buttons, metaData, display, columnGrid, translations, onButtonGridClick, getBlock, getFilterType}) => {

    const [useOnce, setUseOnce] = useState(true);
    let { id } = useParams()

    useEffect( () => {
        if(useOnce) {
            // if search block empty we call the api to feed it
            if(parseInt(block.id) === 0 || parseInt(block.id) !== parseInt(id)) {
                getBlock(id)
            }
            getFilterType(id)
            setUseOnce(false)
        }
    }, [useOnce, block.id, id, getFilterType, getBlock])

    const isBigScreen = useMediaQuery({query: '(min-width: 1561px)'})
    const is1560 = useMediaQuery({ query: '(max-width:1560px)' })
    const is1130 = useMediaQuery({ query: '(max-width:1130px)' })

    return (
        <>
            <Navbar title={block.name} metaData={metaData}/>
            <div className='d-flex flex-column justify-content-center pt-3 align-items-center'>
                <div style={{display: isBigScreen ? 'block' : 'none'}}>
                    <BorderIcon block={block} size={'200'} />
                </div>
                <div style={{display: (is1560 && !is1130) ? 'block' : 'none'}}>
                    <BorderIcon block={block} size={'175'} />
                </div>
                <div style={{display: is1130 ? 'block' : 'none'}}>
                    <BorderIcon block={block} size={'125'} borderSize='5px' sizeIcon='fa-3x' />
                </div>
                <div id='search-grid-title' className='mt-4'>
                    <h1>
                        {
                            (translations['EPB_SEARCH_TITLE_' + block.searchType.toUpperCase()]
                                ?? 'EPB_SEARCH_TITLE_' + block.searchType.toUpperCase()).toUpperCase()
                        }
                    </h1>
                </div>
            </div>
            <div className='mt-4 ms-5'>
                <SearchGridRow buttons={buttons}
                               gridTemplateColumns={metaData.gridTemplateColumns}
                               colors={metaData.defaultColors}
                               onButtonGridClick={(button) => onButtonGridClick(button, block.name)}
                               columnGrid={columnGrid}
                               nbColumn={(display === 'landscape') ? 5 : 2}/>
            </div>
        </>
    )
}

SearchGrid.defaultProps = {
    block: {
        id: 0,
        name: '',
        size: 'small',
        type: 'search',
        searchType: "",
        img:  null,
        icon:  'fas fa-door-open',
        textColor: '#2BB85A',
        bgColor: '#2BB85A',
        displayMode: 'research',
        title: '',
    },
    metaData: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        defaultColors: [
            'lightblue',
            'grey',
            'black'
        ]
    },
    buttons: [],
    columnGrid: 0,
    display: 'landscape',
    translations: {}
}

const mapStateToProps = (state, ownProps) => {
    if(state.block && state.block.searchBlock && state.block.searchBlock.id !== 0) {
        return {
            block: state.block.searchBlock,
            buttons: state.filter.buttons,
            columnGrid: state.boards.board.columnGrid,
            display: state.boards.board.display,
            translations: state.translation.translations
        }
    } else {
        return {
            ...ownProps,
            buttons: state.filter.buttons,
            columnGrid: state.boards.board.columnGrid,
            display: state.boards.board.display,
            translations: state.translation.translations
        }
    }
};

export default connect(mapStateToProps, {getFilterType, getBlock})(SearchGrid)
import {
    GET_E_POSTER_LIST,
    GET_E_POSTER,
    E_POSTER_RATING_SUCCESS,
    E_POSTER_COMMENT_SUCCESS,
    E_POSTER_DEFAULT
} from '../types';

const initialState = {
    count: 0,
    ePosters: [],
    ePoster: {
        id: 0,
        title: "",
        medias: [],
        ref: "",
        avgRating: 0,
        countViews: 0
    },
    isRatingSuccess: false,
    isCommentSuccess: false
};

export default function foo(state = initialState, action) {
    switch (action.type) {
        case GET_E_POSTER_LIST:
            return {
                ...state,
                count: action.payload.count,
                ePosters: action.payload.ePosters,
            }
        case GET_E_POSTER:
            return {
                ...state,
                ePoster: action.payload,
                isRatingSuccess: false,
                isCommentSuccess: false
            }
        case E_POSTER_RATING_SUCCESS:
            return {
                ...state,
                isRatingSuccess : true
            }
        case E_POSTER_COMMENT_SUCCESS:
            return {
                ...state,
                isCommentSuccess: true
            }
        case E_POSTER_DEFAULT:
            return {
                ...state,
                isRatingSuccess: false,
                isCommentSuccess: false
            }
        default:
            return state;
    }
}
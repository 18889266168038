import './Navbar.css'
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import Language from "./Language";
import {getConfig} from "../redux/actions/configAction";

const Navbar = ({title, translations, currentLanguage, getConfig, metaData}) => {
    /*KEEP IT IN CASE WE WANT TO USE A BURGER MENU*/
    // const [showMobileNavbar, setShowMobileNavbar] = useState(false)
    // const [firstPropagation, setFirstPropagation] = useState(false)
    const history = useHistory();
    const handleClickLanguage = (lang) => {
        getConfig(true, lang)
    }

    /*KEEP IT IN CASE WE WANT TO USE A BURGER MENU*/
    // document.onclick = function(e) {
    //     const navbarMobileMenu = document.getElementById('navbar-section-mobile-menu')
    //     if(firstPropagation) {
    //         if (navbarMobileMenu && !navbarMobileMenu.contains(e.target)) {
    //             setShowMobileNavbar(false)
    //         }
    //     } else {
    //         setFirstPropagation(true)
    //     }
    // };
    //
    // const handleOnClickShowMobileNavbar = (show) => {
    //     setShowMobileNavbar(show)
    //     setFirstPropagation(false)
    // }

    return (
        <>
            <div id='navbar-section' className='d-flex flex-row align-items-center 'style={{backgroundColor: metaData.defaultColors[0], color: metaData.defaultColors[2]}}>
                <div id='navbar-title' className='me-auto'>
                    <strong>{decodeURIComponent(title).replace(/<[^>]+>/g, ' ')}</strong>
                </div>
                <Language currentLanguage={currentLanguage} onClickLanguage={(language) => handleClickLanguage(language)} />
                <div id='navbar-home' className='p-2 ms-3 me-5'>
                    <div className='d-flex flex-row align-items-center navBarButton' onClick={() => history.push('/')}>
                        <i id='navbar-home-icon' className='navbarIcon fas fa-home me-3' style={{color: metaData.defaultColors[2]}}/>
                        <strong id='navbar-home-text'>{translations['EPB_NAVBAR_HOME']  ?? 'EPB_NAVBAR_HOME'}</strong>
                    </div>
                </div>
                <div id='navbar-previous' className='p-2 ms-3 me-4'>
                    <div className='d-flex flex-row align-items-center  navBarButton' onClick={history.goBack}>
                        <i id='navbar-previous-icon' className='navbarIcon fas fa-arrow-left me-3' style={{color: metaData.defaultColors[2]}}/>
                        <strong className='ms-2' id='navbar-previous-text'>{translations['EPB_NAVBAR_PREVIOUS']  ?? 'EPB_NAVBAR_PREVIOUS'}</strong>
                    </div>
                </div>
            </div>
            <div id='div-block-navbar' />

            {/*KEEP IT IN CASE WE WANT TO USE A BURGER MENU*/}
            {/*<div id='navbar-section-mobile'>*/}
            {/*    <div onClick={() => handleOnClickShowMobileNavbar(true)} id='navbar-burger' className='d-flex align-items-center justify-content-center'>*/}
            {/*        <i className="fas fa-3x fa-bars" />*/}
            {/*    </div>*/}
            {/*    <div style={{display: showMobileNavbar ? 'block' : 'none'}}>*/}
            {/*        <div id='navbar-section-mobile-menu' className='d-flex flex-column'>*/}
            {/*            <div id='navbar-title-mobile' className='d-flex align-items-center justify-content-center'>*/}
            {/*                <strong>{title.replace(/<[^>]+>/g, ' ')}</strong>*/}
            {/*            </div>*/}
            {/*            <div className='hrStyleWhite' />*/}
            {/*            <div id='navbar-home-language'  className='mt-4 ms-3 me-5'>*/}
            {/*                <Language currentLanguage={currentLanguage}*/}
            {/*                          onClickLanguage={(language) => handleClickLanguage(language)}*/}
            {/*                          size='2em'*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div id='navbar-home-mobile' className='mt-4 p-2 ms-3 me-5'>*/}
            {/*                <div className='navBarButton d-flex align-items-center ' onClick={() => history.push('/')}>*/}
            {/*                    <i className='navbarIcon fas fa-home me-4'/>*/}
            {/*                    <strong>{translations['EPB_NAVBAR_HOME']  ?? 'EPB_NAVBAR_HOME'}</strong>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div id='navbar-previous-mobile' className='p-2 ms-3 me-4'>*/}
            {/*                <div className='navBarButton d-flex align-items-center' onClick={history.goBack}>*/}
            {/*                    <i className='navbarIcon fas fa-arrow-left me-4' />*/}
            {/*                    <strong>{translations['EPB_NAVBAR_PREVIOUS']  ?? 'EPB_NAVBAR_PREVIOUS'}</strong>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>

    );
}

Navbar.defaultProps = {
    translations: {}
}

const mapStateToProps = state => {
    return {
        translations: state.translation.translations,
        currentLanguage: state.translation.currentLanguage
    }
};

export default connect(mapStateToProps, {getConfig})(Navbar)
import BorderIcon from "./BorderIcon";
import './SearchFilter.css'
import Hr from "./Hr";
import {useState} from "react";
import {useMediaQuery} from "react-responsive";

const SearchFilter = ({block, placeholder, onChangeText}) => {
    const [letters, setLetters] = useState(
        [...Array(26).keys()].map(i => {
            return {
                letter: String.fromCharCode('A'.charCodeAt(0) + i),
                isSelected: false
            }
        }
    ))

    const [filterLetters, setFilterLetters] = useState([])

    const handleClick = (i) => {
        //Multiple letter filter
        // **KEEP CODE**
        //
        // const tempLetters = [...letters];
        // let tempFilterLetters = [...filterLetters];
        // if(tempLetters[i].isSelected) {
        //     const index = tempFilterLetters.indexOf(tempLetters[i].letter);
        //     if (index > -1) {
        //         tempFilterLetters.splice(index, 1)
        //     }
        // } else {
        //     tempFilterLetters.push(tempLetters[i].letter)
        // }
        // tempLetters[i].isSelected = !tempLetters[i].isSelected
        // setLetters(tempLetters)
        // setFilterLetters(tempFilterLetters)

        const tempLetters = [...letters];
        let tempFilterLetters = [];

        if(tempLetters[i].isSelected) {
            tempLetters[i].isSelected = !tempLetters[i].isSelected
            setFilterLetters([])
            setLetters(tempLetters)
        } else {
            tempLetters.forEach((tempLetter, index) => {
                tempLetters[index].isSelected = false
            })
            tempLetters[i].isSelected = true
            tempFilterLetters.push(tempLetters[i].letter)

            setFilterLetters(tempFilterLetters)
            setLetters(tempLetters)
        }

        document.getElementById('search-filter-input').value = '';
        onChangeText(document.getElementById('search-filter-input').value, tempFilterLetters)
    }

    const isBigScreen = useMediaQuery({query: '(min-width: 1561px)'})
    const is1560 = useMediaQuery({ query: '(max-width:1560px)' })

    return (
        <>
            <div className='d-flex p-5 justify-content-center align-items-center'>
                <div style={{display: isBigScreen ? 'block' : 'none'}}>
                    <BorderIcon block={block} size={'160'} />
                </div>
                <div style={{display: (is1560) ? 'block' : 'none'}}>
                    <BorderIcon block={block} size={'120'} borderSize={'5px'} sizeIcon={'fa-3x'}/>
                </div>
                <div className='d-flex m-4 sectionSearchFilter fullWidth'>
                    <div className='d-flex flex-column ms-3 fullWidth'>
                        <div>
                            <input id='search-filter-input'
                                   className='ps-3 inputSearchFilter'
                                   placeholder={placeholder.toUpperCase()}
                                   onChange={(e) => {
                                        setFilterLetters([])
                                        setLetters(
                                            [...Array(26).keys()].map(i => {
                                                return {
                                                    letter: String.fromCharCode('A'.charCodeAt(0) + i),
                                                    isSelected: false
                                                }
                                            }
                                        ))
                                        
                                        return onChangeText(e.target.value, [])}
                                    }
                                   autoComplete='off'
                            />
                        </div>
                        <div className='row align-items-start mt-4 ms-1 alphabeticalSection'>
                            {letters.map((letter, i) => {
                                return (
                                    <div style={{maxWidth: '45px', color: (letter.isSelected ? '#B8B8B8': '#4A4A4A')}}
                                         key={i}
                                         className='col linkLetter' onClick={() => handleClick(i)}>
                                        <strong>{letter.letter}</strong>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-3 me-5 ms-5'>
                <Hr />
            </div>
        </>
    )
}

SearchFilter.defaultProps = {
    block: {
        id: 0,
        name: '',
        size: 'small',
        type: 'search',
        searchType: null,
        img:  null,
        icon:  'fas fa-door-open',
        textColor: '#2BB85A',
        bgColor: '#2BB85A',
        displayMode: 'research',
        title: '',
    },
    placeholder: ''
}

export default SearchFilter
import './BorderIcon.css'

const BorderIcon = ({block, size, sizeIcon, borderSize}) => {
    return (
        <>
            <div
                className='gridBlock'
                style={{
                    border: 'solid ' + borderSize,
                    height: size + 'px',
                    width: block.size === 'small' ? size + 'px' : (parseInt(size) * 2) + 'px',
                    color: block.textColor,
                    backgroundColor: block.bgColor,
                    backgroundImage: (block.img === null || block.img === '') ? 'none' : 'url("' + block.img + '")',
                    backgroundSize: (block.img === null || block.img === '') ? 'auto' : 'cover',
                    backgroundPosition: (block.img === null || block.img === '') ? '0%' : 'center',
                    backgroundRepeat: (block.img === null || block.img === '') ? 'repeat' : 'no-repeat',
                }}
            >
                <div style={{height:'100%'}} className='d-flex flex-row align-items-center justify-content-center'>
                    <i className={sizeIcon + ' ' + block.icon}/>
                </div>
            </div>
        </>
    )
}

BorderIcon.defaultProps = {
    block: {
        img:  null,
        icon:  'fas fa-door-open',
        textColor: '#2BB85A',
        bgColor: '#2BB85A',
    },
    size: '200',
    sizeIcon: 'fa-4x',
    borderSize: '7px'
}

export default BorderIcon
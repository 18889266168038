import './ButtonGrid.css'

const ButtonGrid = ({button, txtColor, bgColor, onButtonGridClick}) => {
    return (
        <>
            <div onClick={() => onButtonGridClick(button)} className='buttonGridLink'>
                <div className='buttonGrid'
                     style={{color: txtColor, backgroundColor: bgColor, borderColor: bgColor}} >
                    <div style={{height:'100%'}} className='d-flex flex-row align-items-center justify-content-center'>
                        <strong className='buttonGridText' dangerouslySetInnerHTML={{__html: button.name}} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ButtonGrid
import {useState} from 'react'

const Authentication = ({onSubmit, invalidAuthentication, invalidInputApiUrl, invalidInputToken, tokenInit, urlInit, errors}) => {
    const [apiUrl, setApiUrl] = useState(urlInit ? urlInit : '')
    const [token, setToken] = useState(tokenInit ? tokenInit : '')

    return (
        <div>
            <h3>Authentication information:</h3>
            <form onSubmit={(e) => onSubmit(e, apiUrl, token)}>
                <div className='mb-3 mt-5' />
                <div className="mb-3" style={{color: 'red', display: invalidAuthentication ? 'block' : 'none'}}>
                    {errors.text}
                </div>
                <div className='input-group'>
                    <input type='text'
                           className={'form-control' + invalidInputApiUrl}
                           placeholder='Api url ...'
                           value={apiUrl}
                           onChange={(e) => setApiUrl(e.target.value)}
                           autoComplete='off'
                    />
                    <div className="invalid-feedback">
                        Api url is required
                    </div>
                </div>
                <div className='input-group mb-3 mt-3'>
                    <input type='text'
                           className={'form-control' + invalidInputToken}
                           placeholder='Token ...'
                           value={token}
                           onChange={(e) => setToken(e.target.value)}
                           autoComplete='off'
                    />
                    <div className="invalid-feedback">
                        Token is required
                    </div>
                </div>
                <div className='d-flex justify-content-end mt-4'>
                    <button type="submit" className="btn btn-primary">Next</button>
                </div>
            </form>
        </div>
    );
}

Authentication.defaultProps = {
    invalidAuthentication: '',
    invalidInputApiUrl: '',
    invalidInputToken: '',
    errors: {
        text: '',
        code: 0
    }
}

export default Authentication

import {GET_CSS} from "../types";

const initialState = {
    css: ''
};

export default function foo(state = initialState, action) {
    switch (action.type) {
        case GET_CSS:
            return {
                ...state,
                css: action.payload,
            }
        default:
            return state;
    }
}
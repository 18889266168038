import {
    GET_E_POSTER_LIST,
    GET_E_POSTER,
    HTTP_CODE_CREATED,
    ADD_RATING,
    ADD_COMMENT,
    ADD_CONTAINER_VIEW,
    ADD_MEDIA_PDF_VIEW,
    ADD_MEDIA_VDO_VIEW,
    E_POSTER_RATING_SUCCESS,
    E_POSTER_COMMENT_SUCCESS,
    SOURCE_STAT_E_POSTER, ADD_MAIL, E_POSTER_MAIL_SUCCESS
} from '../types';
import {call} from "../api";
import {getBoard} from "./boardAction";

export const getEPosterList = () => async (dispatch, getState) => {
    const state = getState();
    const url = state.authenticate.url + 'api/eposter/list/' + state.boards.board.id;
    let response = await (
        await call(
            'GET',
            url,
            {},
            {},
            state,
            GET_E_POSTER_LIST
        )
    )(dispatch);

    if (response.data !== undefined) {
        dispatch({
            type: GET_E_POSTER_LIST,
            payload: {
                count: response.data.count,
                ePosters: response.data.ePosters,
            },
        });
    }
};

export const getEPoster = (id) => async (dispatch, getState) => {
    const state = getState();
    const url = state.authenticate.url + 'api/eposter-board/' + state.boards.board.id + '/eposter/' + id;
    let response = await (
        await call(
            'GET',
            url,
            {},
            {},
            state,
            GET_E_POSTER
        )
    )(dispatch);

    if (response.data !== undefined) {
        dispatch({
            type: GET_E_POSTER,
            payload: transformEPoster(response.data),
        });
    }
}

export const transformEPoster = (data) => {
    let medias = []
    if (data.listMedias.images !== undefined) {
        data.listMedias.images.forEach((image) => {
            medias.push(
                {
                    id: image.vdoId,
                    type: 'img',
                    urlDistant: image.urlDistant,
                    urlLocal: image.urlLocal ?? ''
                }
            )
        })
    }

    if (data.listMedias.video !== undefined) {
        medias.push(
            {
                id: data.listMedias.video.vdoId,
                type: 'video',
                urlDistant: data.listMedias.video.urlDistant,
                urlLocal: data.listMedias.video.urlLocal ?? ''
            }
        )
    }
    delete data.listMedias
    data.medias = medias

    return data
}

export const addRating = (rating) => async (dispatch, getState) => {
    const state = getState();

    if (state.boards.board.isRating) {
        const url = state.authenticate.url + 'api/container/rating/' + state.ePoster.ePoster.id;
        let bodyFormData = new FormData();

        bodyFormData.append('rate', rating);
        let response = await (
            await call(
                'POST',
                url,
                {},
                bodyFormData,
                state,
                ADD_RATING
            )
        )(dispatch);

        if (response.code !== undefined && response.code === HTTP_CODE_CREATED) {
            dispatch({
                type: E_POSTER_RATING_SUCCESS,
                payload: false,
            });
        }
    }
}

export const addComment = (comment) => async (dispatch, getState) => {
    const state = getState();

    if (state.boards.board.isComment) {
        const url = state.authenticate.url + 'api/container/comment/' + state.ePoster.ePoster.id;
        let bodyFormData = new FormData();

        bodyFormData.append('comment', decodeURI(comment));
        let response = await (
            await call(
                'POST',
                url,
                {},
                bodyFormData,
                state,
                ADD_COMMENT,
            )
        )(dispatch);

        if (response.code !== undefined && response.code === HTTP_CODE_CREATED) {
            dispatch({
                type: E_POSTER_COMMENT_SUCCESS,
                payload: false,
            });
        }
    }
}

export const addMail = (mail) => async (dispatch, getState) => {
    const state = getState();

    if (state.boards.board.isSendingMail) {
        const url = state.authenticate.url + 'api/container/mail/' + state.ePoster.ePoster.id + '?board=' + state.boards.board.id ;
        let bodyFormData = new FormData();

        bodyFormData.append('mail', decodeURI(mail));
        let response = await (
            await call(
                'POST',
                url,
                {},
                bodyFormData,
                state,
                ADD_MAIL,
            )
        )(dispatch);

        if (response.code !== undefined && response.code === HTTP_CODE_CREATED) {
            dispatch({
                type: E_POSTER_MAIL_SUCCESS,
                payload: false,
            });
        }
    }
}

export const addContainerView = (id) => async (dispatch, getState) => {
    const state = getState();

    const url = state.authenticate.url + 'api/container/stat/visit/' + id;
    let bodyFormData = new FormData();

    bodyFormData.append('sourceStat', SOURCE_STAT_E_POSTER);
    await (
        await call(
            'POST',
            url,
            {},
            bodyFormData,
            state,
            ADD_CONTAINER_VIEW
        )
    )(dispatch);
}

export const addMediaDocView = (id) => async (dispatch, getState) => {
    const state = getState();

    const url = state.authenticate.url + 'api/media/stat/view/' + id;
    let bodyFormData = new FormData();

    bodyFormData.append('sourceStat', SOURCE_STAT_E_POSTER);
    await (
        await call(
            'POST',
            url,
            {},
            bodyFormData,
            state,
            ADD_MEDIA_PDF_VIEW
        )
    )(dispatch);
}

export const addMediaVideoView = (id, duration) => async (dispatch, getState) => {
    const state = getState();

    const url = state.authenticate.url + 'api/media/stat/view/' + id;
    let bodyFormData = new FormData();

    bodyFormData.append('duration', duration);
    bodyFormData.append('sourceStat', SOURCE_STAT_E_POSTER);
    await (
        await call(
            'POST',
            url,
            {},
            bodyFormData,
            state,
            ADD_MEDIA_VDO_VIEW
        )
    )(dispatch);
}
import {combineReducers} from 'redux';
import boardReducer from './boardReducer';
import authenticateReducer from "./authenticateReducer";
import blockReducer from "./blockReducer";
import ePosterReducer from "./ePosterReducer";
import filterReducer from "./filterReducer";
import errorReducer from "./errorReducer";
import translationReducer from "./translationReducer";
import cssReducer from "./cssReducer";

const appReducer = combineReducers({
    boards: boardReducer,
    authenticate: authenticateReducer,
    block: blockReducer,
    ePoster: ePosterReducer,
    filter: filterReducer,
    error: errorReducer,
    translation: translationReducer,
    css: cssReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_STORE') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer
import {
    GET_ALPHABETICAL_SEARCH_LIST,
    GET_GRID_SEARCH_LIST,
    SEARCH_TYPE_ALPHABETIC_LIST,
    SEARCH_TYPE_GRID
} from '../types';
import {call} from "../api";

export const getFilterType = (id) => async (dispatch, getState) => {
    const state = getState();
    const url = state.authenticate.url + 'api/eposter/filter-type/' + id;
    let bodyFormData = new FormData();

    bodyFormData.append('eposter-board-id', state.boards.board.id);

    let response = await (
        await call(
            'POST',
            url,
            {},
            bodyFormData,
            state,
            SEARCH_TYPE_ALPHABETIC_LIST
        )
    )(dispatch);

    if (response.data !== undefined) {
        switch (response.data.displayMode) {
            case SEARCH_TYPE_ALPHABETIC_LIST:
                dispatch({
                    type: GET_ALPHABETICAL_SEARCH_LIST,
                    payload:
                        addAlphabetical(response.data.list)
                })
                break;
            case SEARCH_TYPE_GRID:
                dispatch({
                    type: GET_GRID_SEARCH_LIST,
                    payload: response.data.list
                })
                break;
            default:
                return;
        }
    }
};

export const addAlphabetical = (authors) => {
    let letter = ''
    let data = []

    authors.forEach((author) => {
        if (author.name.toUpperCase()[0] !== letter) {
            letter = author.name.toUpperCase()[0]
            data.push({letter: letter})
        }
        data.push(author)
    })

    return data
}

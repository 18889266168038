import { useSelector } from 'react-redux'
import './ButtonSession.css'

const ButtonSession = ({session, blockTitle, onButtonSessionClick}) => {

    const {primaryColor, secondaryColor, ternaryColor} = useSelector((store) => {
        return {
            primaryColor: store.boards.board.primaryColor,
            secondaryColor: store.boards.board.secondaryColor,
            ternaryColor: store.boards.board.ternaryColor,
        }
    })

    return (
        <>
            <div onClick={() => onButtonSessionClick(session.session_reference, blockTitle)} className='buttonSessionLink'>
                <div className='buttonSession'>
                    <div className='d-flex flex-column h-100'>
                        <div className='session-reference__text' style={{color: ternaryColor, backgroundColor: secondaryColor}}>{session.session_reference}</div>
                        <div
                            className='d-flex justify-content-center session-title__text h-100 pt-4'
                            style={{color: ternaryColor, backgroundColor: primaryColor}}>
                            <strong className='buttonSessionText' dangerouslySetInnerHTML={{__html: session.session_title}} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ButtonSession
import {connect} from "react-redux";
import './Error.css'
import {useEffect, useState} from "react";
import {resetError} from "../../redux/actions/errorAction";
import {useHistory} from "react-router-dom";

const Error = ({error, metaData, onClick, translations, resetError}) => {
    const [useOnce, setUseOnce] = useState(true);
    const [displayError, setDisplayError] = useState(true);
    const history = useHistory();
    useEffect(() => {
        if(useOnce) {
            if(error.code === 0) {
                history.push('/')
            }
            setDisplayError(error)
            resetError()
        }
        setUseOnce(false)
    }, [resetError, useOnce, error])
    return (
        <>
            <div className='d-flex align-items-center justify-content-center' style={{marginTop: '25vh'}}>
                <div className='px-5 py-2'
                     id='border-section'
                     style={{borderColor: (metaData.defaultColors[1] !== '' ? metaData.defaultColors[1] : '#5a6268')}}>
                    <div className={'d-flex flex-column align-items-center justify-content-center pt-5 pb-3'}>
                        <h2>{translations['EPB_ERROR_ERROR']  ?? 'ERROR'} {displayError.code}</h2>
                        <div id='code-error' className='mt-2'><code><i>{displayError.text}</i></code></div>
                        <div className='d-flex mt-4'>
                            <div className='ms-auto'>
                                <div id='error-button'
                                     className={'p-2 px-4'}
                                     onClick={onClick}
                                     style={{backgroundColor: (metaData.defaultColors[1] !== '' ? metaData.defaultColors[1] : '#5a6268')}}
                                >
                                    {translations['EPB_ERROR_RETURN_HOME']  ?? 'BACK TO HOMEPAGE'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

Error.defaultProps = {
    error: {
        code: 0,
        text: ''
    },
    buttonColor: 'blue'
}

const mapStateToProps = state => {
    return ({
        error: state.error,
        translations: state.translation.translations
    })
};

export default connect(mapStateToProps, {resetError})(Error)
import {GET_BLOCK, GET_BLOCK_LIST} from '../types';

const initialState = {
    blocks: [],
    searchBlock: {
        id: 0,
        name: '',
        size: 'small',
        type: 'search',
        searchType: null,
        img:  null,
        icon:  'fas fa-door-open',
        textColor: '#2BB85A',
        bgColor: '#2BB85A',
        displayMode: 'research',
        title: '',
    }
};

export default function foo(state = initialState, action) {
    switch (action.type) {
        case GET_BLOCK_LIST:
            return {
                ...state,
                blocks: action.payload.blocks,
            }
        case GET_BLOCK:
            return {
                ...state,
                searchBlock: action.payload,
            }
        default:
            return state;
    }
}
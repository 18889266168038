const orderByDate = (a, b) => {
    // Convert date
    const startDateA = new Date(a.interventionDateStart);
    const startDateB = new Date(b.interventionDateStart);
    const endDateA = new Date(a.interventionDateEnd);
    const endDateB = new Date(b.interventionDateEnd);

    // check start date
    if (startDateA < startDateB) return -1;
    if (startDateA > startDateB) return 1;

    // check date end if date start equal
    if (endDateA < endDateB) return -1;
    if (endDateA > endDateB) return 1;

    return 0;
};

export let orderEposters = (ePosters, searchTypes, blockSearchType) => {
    searchTypes.forEach(element => {
        if (element.name === blockSearchType) {
            switch (element.searchOrder) {
                case 'reference':
                    ePosters.sort((a, b) => a.interventionReference.localeCompare(b.interventionReference, undefined, { sensitivity: 'base' }));
                    break;
                case 'date':
                    ePosters.sort(orderByDate);
                    break;
                case 'title':
                default:
                    ePosters.sort((a, b) => a.eposter_title.localeCompare(b.eposter_title, undefined, { sensitivity: 'base' }));
                    break;
            }
        }
    });

    return ePosters;
}
import {GET_BLOCK_LIST, GET_BLOCK} from '../types'
import {call} from "../api";

export const getBlock = (id) => async (dispatch, getState) => {
    const state = getState();
    const url = state.authenticate.url + 'api/eposter-board-block/' + id;
    let response = await (
        await call(
            'GET',
            url,
            {},
            {},
            state,
            GET_BLOCK
        )
    )(dispatch);

    if (response.data !== undefined) {
        dispatch({
            type: GET_BLOCK,
            payload: {
                id: parseInt(response.data.id),
                name: response.data.name,
                size: response.data.size,
                type: response.data.type,
                searchType: response.data.searchType,
                img:  response.data.bgPicture,
                icon:  response.data.bgIcon,
                textColor: response.data.textColor,
                bgColor: response.data.bgColor,
                displayMode: response.data.displayMode,
                title: response.data.title,
            }
        })
    }
};

export const getBlockList = () => async (dispatch, getState) => {
    const state = getState();
    const url = state.authenticate.url + 'api/eposter-board-block/' + state.boards.board.id +'/list';
    let response = await (
        await call(
            'GET',
            url,
            {},
            {},
            state,
            GET_BLOCK_LIST
        )
    )(dispatch);

    if (response.data !== undefined) {
        let blockList = [];

        for (let block of response.data.blockList) {
            blockList.push({
                id: parseInt(block.id),
                name: block.name,
                size: block.size,
                type: block.type,
                searchType: block.searchType,
                img:  block.bgPicture,
                icon:  block.bgIcon,
                textColor: block.textColor,
                bgColor: block.bgColor,
                displayMode: block.displayMode,
                title: block.title,
                btnLink: block.btnLink
            });
        }

        const dataBlocks = transformDataBlocks(blockList, state.boards.board.display);
        dispatch({
            type: GET_BLOCK_LIST,
            payload: {
                blocks: dataBlocks,
            }
        })
    }
};

const transformDataBlocks = (blocks, type) => {
    switch(type) {
        case 'portrait':
            return displayDataBlocks(blocks,2, 'portrait')
        case 'landscape':
        default:
            return displayDataBlocks(blocks, 6, 'landscape')
    }
}

/**
 *
 * @param blocks
 * @param nbColumn
 * @param displayMode
 * @returns {*[]}
 */
const displayDataBlocks = (blocks, nbColumn, displayMode) => {
    let objectRows = []
    let objectBlocks = []
    let countColumn = 0

    blocks.forEach((block, i) => {
        countColumn = incrementBySize(block.size, countColumn, displayMode)

        if(countColumn > nbColumn) {
            objectRows.push(setObjectRows(objectBlocks, nbColumn, displayMode))
            objectBlocks = []
            objectBlocks.push(block)
            countColumn = 0
            countColumn = incrementBySize(block.size, countColumn, displayMode)
        } else if(countColumn === nbColumn) {
            objectBlocks.push(block)
            objectRows.push(setObjectRows(objectBlocks, nbColumn, displayMode))
            objectBlocks = []
            countColumn = 0
        } else {
            objectBlocks.push(block)
        }

        if(i === blocks.length - 1 && objectBlocks.length > 0) {
            objectRows.push(setObjectRows(objectBlocks, nbColumn, displayMode))
        }
    })
    return objectRows
}

const incrementBySize = (size, count, displayMode) => {
    if(size === 'big') {
        if (displayMode === 'portrait') {
            count += 2
        } else {
            count += 3
        }
    } else {
        count++
    }

    return count
}

const setObjectRows = (objectBlocks, count, displayMode) => {
    let gridTemplateColumns = ''
    let countElement = 0

    objectBlocks.forEach(function (objectBlock) {
        if(objectBlock.size === 'big') {
            if (displayMode === 'portrait') {
                gridTemplateColumns += ' 2fr'
                countElement += 2
            } else {
                gridTemplateColumns += ' 3fr'
                countElement += 3
            }

        } else {
            gridTemplateColumns += ' 1fr'
            countElement++
        }
    });
    if(countElement !== count) {
        gridTemplateColumns +=  ' ' + (count - countElement) + 'fr'
    }

    return {
        gridTemplateColumns: gridTemplateColumns,
        blocks: objectBlocks
    }
}
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {connect} from "react-redux";
import StarRating from "react-svg-star-rating";

import {
    getEPoster,
    addRating,
    addComment,
    addMediaDocView,
    addMediaVideoView,
    addMail
} from "../../redux/actions/ePosterAction";

import Navbar from "../../components/Navbar";
import Hr from "../../components/Hr";
import Carousel from "../../components/Carousel";

import './EPoster.css'
import './EPosterPrint.css'
import ModalComment from "../../components/ModalComment";
import ModalMail from "../../components/ModalMail";
import ModalEPoster from "../../components/ModalEPoster";
import {useMediaQuery} from "react-responsive";
import NoMatchFound from "../../components/NoMatchFound";

const EPoster = ({
    ePoster,
    metaData,
    isRatingSuccess,
    isCommentSuccess,
    translations,
    board,
    getEPoster,
    addRating,
    addComment,
    addMediaDocView,
    addMediaVideoView,
    addMail
}) => {
    const [slider, setSlider] = useState()
    const [sliderFullScreen, setSliderFullScreen] = useState()
    const [indexVideo, setIndexVideo] = useState(-1)
    const [isImage, setIsImage] = useState(false)
    const [isVideo, setIsVideo] = useState(false)
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [showComment, setShowComment] = useState(false);
    const [showMailSending,setShowMail] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [lockIdForStats, setLockIdForStats] = useState([]);
    const [didMount, setDidMount] = useState(false);
    const [useRatingOnce, setUseRatingOnce] = useState(true);
    const [useCommentOnce, setUseCommentOnce] = useState(true);
    const [alert, setAlert] = useState({
        display: false,
        text: ''
    });
    const [isPrintBtnVisible, setIsPrintBtnVisible] = useState(true);

    let { id, title } = useParams()
    const history = useHistory();

    useEffect( () => {
        if(id === undefined) {
            history.push('/')
        }
    })

    useEffect( () => {
        if(didMount) {
            if(showFullScreen) {
                if(sliderFullScreen) {
                    let current = slider.innerSlider.state.currentSlide
                    sliderFullScreen.slickGoTo(current)
                    if (ePoster.medias[current] !== undefined) {
                        let main = document.getElementById('e-poster-video-main' + current)
                        let fullScreen = document.getElementById('e-poster-video-modal' + current);
                        if(ePoster.medias[current].type === 'video') {
                            fullScreen.currentTime = main.currentTime
                            if(!main.paused && showFullScreen) {
                                main.pause()
                                fullScreen.play()
                            }
                        }
                    }
                }
            }
        }
    }, [sliderFullScreen])

    useEffect( () => {
        setIsImage(false)
        setIsVideo(false)
        if(ePoster !== undefined) {
            ePoster.medias.forEach((media, i) => {
                if(media.type === 'img') {
                    setIsImage(true);
                }
                if(media.type === 'video') {
                    setIsVideo(true)
                    setIndexVideo(i)
                }
            })

            if (
                ePoster.medias[0] !== undefined
                && ePoster.medias[0].type === 'img'
                && !lockIdForStats.includes(ePoster.medias[0].id)
                && didMount
            ) {
                let lockIdForStatsClone = Object.assign(lockIdForStats);

                addMediaDocView(ePoster.medias[0].id);
                lockIdForStatsClone.push(ePoster.medias[0].id)
                setLockIdForStats(lockIdForStatsClone);
            }
        }
    }, [addMediaDocView, ePoster, didMount, lockIdForStats])

    useEffect( () => {
        if(didMount && isRatingSuccess && useRatingOnce) {
            setAlert({
                display: true,
                text: (translations['EPB_E_POSTER_RATING_SENT']  ?? 'EPB_E_POSTER_RATING_SENT')
            })
            async function f1() {
                await alertFade();
            }
            f1();
            setUseRatingOnce(false)
        }
    }, [isRatingSuccess, useRatingOnce])

    useEffect( () => {
        if(didMount && isCommentSuccess && useCommentOnce) {
            setAlert({
                display: true,
                text: translations['EPB_E_POSTER_COMMENT_SENT']  ?? 'EPB_E_POSTER_COMMENT_SENT'
            })
            async function f1() {
                await alertFade();
            }
            f1();
            setUseCommentOnce(false)
        }
    }, [isCommentSuccess, useCommentOnce])

    useEffect(  () => {
        if(!didMount) {
            getEPoster(id)
        }
        setDidMount(true);
    }, [getEPoster, id])



    function alertFade() {
        return new Promise(resolve => {
            setTimeout(() => {
                setAlert({
                    display: false,
                    text: ''
                })
            }, 5000);
        });
    }

    const goToPdf = () => {
        slider.slickGoTo(0)
    }

    const goToVideo = () => {
        setIsPrintBtnVisible(false);
        slider.slickGoTo(indexVideo)
    }

    const printImage = () => {
        window.print();
    }

    const setSliderCarousel = (slider) => {
        setSlider(slider)
    }

    const setSliderFullScreenCarousel = (slider) => {
        setSliderFullScreen(slider)
    }

    const openFullScreen = () => {
        setShowFullScreen(true)

    }

    const handleOnClickRating = (rating) => {
        addRating(rating)
        setIsReadOnly(true)
    }

    const handleOnClickComment = (show) => {
        setShowComment(show)
    }

    const handleOnClickMailSending = (show) => {
        setShowMail(show)
    }

    const handleOnClickFullScreen = (show) => {
        setShowFullScreen(show)
        if(!show) {
            let current = sliderFullScreen.innerSlider.state.currentSlide
            slider.slickGoTo(current)
            if (ePoster.medias[current] !== undefined) {
                let main = document.getElementById('e-poster-video-main' + current)
                let fullScreen = document.getElementById('e-poster-video-modal' + current);
                if(ePoster.medias[current].type === 'video') {
                    main.currentTime = fullScreen.currentTime
                    if(!fullScreen.paused) {
                        fullScreen.pause()
                        main.play()
                    }
                }
            }
        }
    }

    const handleAddComment = (comment) => {
        addComment(comment)
        setShowComment(false)
    }

    const handleAddMail = (mail) => {
        addMail(mail)
        setShowMail(false)
    }

    const beforeChange = (current, next) => {
        if(ePoster.medias[next].type === 'video') {
            setIsPrintBtnVisible(false);
        } else {
            setIsPrintBtnVisible(true);
        }

        if (ePoster.medias[next] !== undefined && !lockIdForStats.includes(ePoster.medias[next].id)) {
            if (ePoster.medias[next].type === 'img') {
                let lockIdForStatsClone = Object.assign(lockIdForStats);
                addMediaDocView(ePoster.medias[next].id);
                lockIdForStatsClone.push(ePoster.medias[next].id)
                setLockIdForStats(lockIdForStatsClone);
            }
        }

        if (ePoster.medias[current] !== undefined) {
            if(ePoster.medias[current].type === 'video') {
                let fullScreen = document.getElementById('e-poster-video-modal' + current);
                let main = document.getElementById('e-poster-video-main' + current)
                if(current !== next) {
                    main.pause()
                }
                if(fullScreen) {
                    fullScreen.pause()
                }
            }
        }
    }

    const afterChange = (current) => {
        if(ePoster.medias[current].type === 'video') {
            setIsPrintBtnVisible(false);
        } else {
            setIsPrintBtnVisible(true);
        }
    }

    const isBigScreen = useMediaQuery({query: '(min-width: 951px)'})
    const is870 = useMediaQuery({query: '(min-width: 870px)'})
    const is1280 = useMediaQuery({ query: '(max-width:1280px)' })
    const is1200 = useMediaQuery({ query: '(max-width:1200px)' })

    const settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        arrows: true,
        beforeChange: beforeChange,
        afterChange: afterChange,
    }

    const settingsFullScreen = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        width: '100%',
        arrows: false,
        beforeChange: beforeChange,
        afterChange: afterChange,
    }

    return (
        <>
            <Navbar title={decodeURIComponent(title)} metaData={metaData} />
            <div id='alert-e-poster'  className='no-print' style={{display: (alert.display ? 'block' : 'none')}} >
                <div className="alert alert-success" role="alert">
                    {alert.text}
                </div>
            </div>
            <div className='ms-5 pt-4 poster-title'>
                <div className="eposterReference">{ePoster.ref}</div>
                <h3 className="eposterTitle">{ePoster.title}</h3>
            </div>
            <div className='pt-3 me-5 ms-5 no-print'>
                <Hr />
            </div>
            <div style={{width: '80%', margin: 'auto'}} className='mt-5 eposter-button'>
                <div style={{display: (isBigScreen && !is1280) ? 'block' : 'none'}} className='eposter-button__holder'>
                    <div className='d-flex align-items-center'>
                        <div className='d-flex p-4 px-5 me-auto align-items-center eposterBlocButtons'
                             style={{display: ((isImage || isVideo) ? 'block' : 'none'),
                                 color: 'white',
                                 backgroundColor: metaData.defaultColors[0],
                                 borderRadius: '15px'
                             }}>
                            <div style={{display: (isImage ? 'block' : 'none')}} onClick={goToPdf}
                                 className='buttonStyle pe-5 eposterImageButton'><i className='far fa-3x fa-file-pdf'/>
                            </div>
                            <div style={{display: (isVideo ? 'block' : 'none')}} onClick={goToVideo}
                                 className='buttonStyle pe-5 eposterVideoButton'><i
                                className='fas fa-3x fa-play-circle'/></div>
                            <div onClick={openFullScreen}><i
                                className='fas fa-3x fa-expand-arrows-alt buttonStyle eposterFullScreenButton'/></div>
                        </div>
                        <div className="eposterRatingSection" style={{display: board.isRating ? 'block' : 'none'}}>
                            <div className='d-flex align-items-center me-5'>
                                <strong style={{textDecoration: 'underline', fontSize: '30px'}}
                                        className='me-4'>{translations['EPB_E_POSTER_RATING'] ?? 'EPB_E_POSTER_RATING'}</strong>
                                <StarRating
                                    starClassName='me-1'
                                    unit="half"
                                    size={75}
                                    innerRadius={25}
                                    outerRadius={50}
                                    handleOnClick={handleOnClickRating}
                                    initialRating={(ePoster.avgRating)}
                                    isReadOnly={isReadOnly}
                                    activeColor={metaData.defaultColors[0]}
                                />
                            </div>
                        </div>
                        <div style={{display: board.isSendingMail && isImage ? 'block' : 'none'}}>
                            <div onClick={() => handleOnClickMailSending(true)}
                                 className={'d-flex p-4 px-4 align-items-center buttonStyle ms-5' + (isCommentSuccess ? ' disableButtonCommentPoster' : '')}
                                 style={{
                                     color: 'white',
                                     backgroundColor: metaData.defaultColors[0],
                                     borderRadius: '15px'
                                 }}>
                                <div><i className="fas fa-3x fa-envelope"/></div>
                            </div>
                        </div>
                        {isPrintBtnVisible ? <div>
                            <div onClick={printImage}
                                 id='print-ePoster'
                                 className={(isImage && board.isPrintingEnabled ? 'd-flex' : 'd-none') + ' p-4 px-4 align-items-center buttonStyle ms-5'}
                                 style={{
                                     color: 'white',
                                     backgroundColor: metaData.defaultColors[0],
                                     borderRadius: '15px'
                                 }}>
                                <div><i className='fa fa-3x fa-print'/></div>
                            </div>
                        </div> : null}

                        <div style={{display: board.isComment ? 'block' : 'none'}}>
                            <div onClick={() => handleOnClickComment(true)}
                                 className={'d-flex p-4 px-4 align-items-center buttonStyle ms-5' + (isCommentSuccess ? ' disableButtonCommentPoster' : '')}
                                 style={{
                                     color: 'white',
                                     backgroundColor: metaData.defaultColors[0],
                                     borderRadius: '15px'
                                 }}>
                                <div><i className="fas fa-3x fa-comment"/></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{display: is1280 ? 'block' : 'none'}} className='eposter-button__holder'>
                    <div className='d-flex'>
                        <div className='d-flex p-4 px-5 me-auto align-items-center'
                             style={{
                                 display: ((isImage || isVideo) ? 'block' : 'none'),
                                 color: 'white',
                                 backgroundColor: metaData.defaultColors[0],
                                 borderRadius: '15px'
                             }}>
                            <div style={{display: (isImage ? 'block' : 'none')}} onClick={goToPdf}
                                 className='buttonStyle pe-5'><i className='far fa-3x fa-file-pdf' /></div>
                            <div style={{display: (isVideo ? 'block' : 'none')}} onClick={goToVideo} className='buttonStyle pe-5'><i className='fas fa-3x fa-play-circle' /></div>
                            <div onClick={openFullScreen}><i className='fas fa-3x fa-expand-arrows-alt buttonStyle' /></div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='me-auto'>
                                <div style={{display: board.isRating ? 'block' : 'none'}}>
                                    <div className='d-flex align-items-center'>
                                        <strong style={{
                                            textDecoration: 'underline',
                                            fontSize: '30px',
                                            display: is870 ? 'block' : 'none'
                                        }}
                                                className='me-4'>{translations['EPB_E_POSTER_RATING'] ?? 'EPB_E_POSTER_RATING'}</strong>
                                        <StarRating
                                            starClassName='me-1'
                                            unit="half"
                                            size={75}
                                            innerRadius={25}
                                            outerRadius={50}
                                            handleOnClick={handleOnClickRating}
                                            initialRating={(ePoster.avgRating)}
                                            isReadOnly={isReadOnly}
                                            activeColor={metaData.defaultColors[0]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{display: board.isSendingMail && isImage ? 'block' : 'none'}}>
                                <div onClick={() => handleOnClickMailSending(true)}
                                     className={'d-flex p-4 px-4 align-items-center buttonStyle ms-5 ' + (isCommentSuccess ? ' disableButtonCommentPoster' : '')}
                                     style={{
                                         color: 'white',
                                         backgroundColor: metaData.defaultColors[0],
                                         borderRadius: '15px'
                                     }}>
                                    <div><i className="fas fa-3x fa-envelope"/></div>
                                </div>
                            </div>
                            {isPrintBtnVisible ? <div>
                                <div onClick={printImage}
                                     id='print-ePoster'
                                     className={(isImage && board.isPrintingEnabled ? 'd-flex' : 'd-none') + ' p-4 px-4 align-items-center buttonStyle ms-5'}
                                     style={{
                                         color: 'white',
                                         backgroundColor: metaData.defaultColors[0],
                                         borderRadius: '15px',
                                         display: (isImage && board.isPrintingEnabled ? 'block' : 'none')
                                     }}>
                                    <div><i className='fa fa-3x fa-print'/></div>
                                </div>
                            </div> : null}
                            <div style={{display: board.isComment ? 'block' : 'none'}}>
                                <div onClick={() => handleOnClickComment(true)}
                                     className={'d-flex p-4 px-4 align-items-center buttonStyle ms-5 ' + (isCommentSuccess ? ' disableButtonCommentPoster' : '')}
                                     style={{
                                         color: 'white',
                                         backgroundColor: metaData.defaultColors[0],
                                         borderRadius: '15px'
                                     }}>
                                    <div><i className="fas fa-3x fa-comment"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={is1280 ? 'mt-4' : 'mt-5'} style={{margin: 'auto', width: is1200 ? '85%' : '100%'}}>
                    <Carousel medias={(parseInt(ePoster.id) === parseInt(id)) ? ePoster.medias : []}
                              setSliderCarousel={(s) => setSliderCarousel(s)}
                              settings={settings}
                              serverLocalUrl={board.serverLocalUrl}
                              display={board.display}
                              addMediaVideoView={addMediaVideoView}
                              displayType='main'
                    />
                </div>
            </div>
            <div style={{display: (ePoster.medias.length > 0) ? 'none' : 'block'}}>
                <NoMatchFound text={translations['EPB_E_POSTER_NO_MEDIA']  ?? 'EPB_E_POSTER_NO_MEDIA'}/>
            </div>
            <ModalMail show={showMailSending}
                          setShow={(show) => handleOnClickMailSending(show)}
                          addMail={(mail) => handleAddMail(mail)}
                          buttonColor={metaData.defaultColors[1]}
            />
            <ModalComment show={showComment}
                          setShow={(show) => handleOnClickComment(show)}
                          addComment={(comment) => handleAddComment(comment)}
                          buttonColor={metaData.defaultColors[1]}
            />
            <ModalEPoster medias={ePoster.medias}
                          show={showFullScreen}
                          setShow={(show) => handleOnClickFullScreen(show)}
                          serverLocalUrl={board.serverLocalUrl}
                          display={board.display}
                          settings={settingsFullScreen}
                          addMediaVideoView={addMediaVideoView}
                          setSliderCarousel={(s) => setSliderFullScreenCarousel(s)}
            />
        </>
    );
}

EPoster.defaultProps = {
    ePoster: {
        medias: []
    },
    board: {
        isComment: 1,
        isRating: 1,
        display: 'landscape'
    },
    metaData: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        defaultColors: [
            'lightblue',
            'grey',
            'black'
        ],
        translations: {}
    },
}

const mapStateToProps = state => {
    return {
        board: state.boards.board,
        ePoster: state.ePoster.ePoster,
        isRatingSuccess: state.ePoster.isRatingSuccess,
        isCommentSuccess: state.ePoster.isCommentSuccess,
        translations: state.translation.translations
    }
};

export default connect(mapStateToProps, {getEPoster, addRating, addComment, addMediaDocView, addMediaVideoView, addMail})(EPoster)
import PropTypes from "prop-types";
import ButtonText from "./ButtonText";
import './Row.css'
import ButtonImage from "./ButtonImage";

const Row = ({blocks, gridTemplateColumns, onClick}) => {
   return (
       <div className={'wrapper'} style={{gridTemplateColumns: gridTemplateColumns}} >
          {blocks.map((block, i) => {
              if(block.img !== null && block.img !== '') {
                  return <ButtonImage onClick={() => onClick(block)} key={'column-' + i} block={block} />
              } else {
                  return <ButtonText onClick={() => onClick(block)} key={'column-' + i} block={block} />
              }
          })}
       </div>
   )
}

Row.defaultProps = {
    blocks: [],
    gridTemplateColumns: ''
}

Row.propTypes = {
    blocks: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Row
import {ERROR_DEFAULT, ERROR_RESET} from "../types";

const initialState = {
    code: 0,
    text: ''
};

export default function foo(state = initialState, action) {
    switch (action.type) {
        case ERROR_DEFAULT:
            return action.payload
        case ERROR_RESET:
            return {
                code: 0,
                text: ''
            }
        default:
            return state;
    }
}
import InfiniteScroll from "react-infinite-scroll-component";
import {connect} from "react-redux";
import ButtonSession from "../pages/search-research/ButtonSession";

const SearchSessions = ({
    infiniteScrollData,
    fetchData,
    hasMore,
    title,
    metaData,
    displayEndMessage,
    searchType, translations, onSessionClick}) => {

    return (
        <>
            <InfiniteScroll
                dataLength={infiniteScrollData.length}
                next={fetchData}
                hasMore={hasMore}
                loader={
                    <div className='d-flex align-items-center justify-content-center mb-5 pb-5 pt-3'>
                        <i className='fa-4x fas fa-spinner fa-spin'/>
                    </div>
                }
                endMessage={
                    displayEndMessage
                        ?
                            <div className='d-flex align-items-center justify-content-center mb-5 pb-5 pt-3'>
                                <h2><i>{translations['EPB_E_POSTER_LIST_NO_MORE_E_POSTER']  ?? 'EPB_E_POSTER_LIST_NO_MORE_E_POSTER'}</i></h2>
                            </div>
                        : <></>
                }
            >
                <div className={'row row-cols-2 mb-5 mx-4'}>
                    {
                        infiniteScrollData.map((ePoster, i) => {
                            return (
                                <div className="col" key={i}>
                                    <div className={"my-2"}>
                                        <ButtonSession
                                            session={ePoster}
                                            onButtonSessionClick={onSessionClick}
                                            blockTitle={title}
                                            />
                                    </div>
                                </div>
                                )
                        })
                    }
                </div>
            </InfiniteScroll>
        </>
    );
}

SearchSessions.defaultProps = {
    filterEPostersPartial: [],
    fetchData: () => {},
    hasMore: false,
    title: '',
    metaData: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        defaultColors: [
            'lightblue',
            'grey',
            'black'
        ]
    },
    translations: []
}

const mapStateToProps = state => {
        return {
            translations: state.translation.translations
        }
};

export default connect(mapStateToProps, {})(SearchSessions)

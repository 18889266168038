import {Switch, Route, useHistory} from 'react-router-dom'
import Home from "./pages/home/Home";
import Config from './pages/config/Config'
import './App.css'
import {useEffect, useState} from "react";
import EPoster from "./pages/eposter/EPoster";
import SearchGrid from "./pages/search-grid/SearchGrid";
import SearchList from "./pages/search-list/SearchList";
import SearchAlphabetical from "./pages/search-alphabetical/SearchAlphabetical";
import SearchResearch from "./pages/search-research/SearchResearch";
import SearchTitle from "./pages/search-title/SearchTitle";
import ModalLink from "./components/ModalLink";
import {connect} from "react-redux";
import Error from "./pages/error/Error";
import {resetError} from "./redux/actions/errorAction";
import Preview from "./pages/preview/Preview";
import SearchInterventions from './pages/search-intervention/SearchInterventions';
import {getBoard, getBoardList} from "./redux/actions/boardAction";
import {getConfig} from "./redux/actions/configAction";

const App = ({error, resetError, metaData, board, css, getBoard, getConfig}) => {

    const [searchBlock, setSearchBlock] = useState()
    const [showLink, setShowLink] = useState(false);
    const [link, setLink] = useState('');
    const inactivityDurationTime = board.isAutoBackupEnabled ? board.inactivityDurationTime : 0;
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());
    const history = useHistory();
    const inactivityEnabled = board.isAutoBackupEnabled && board.inactivityDurationTime > 0;

    // Routing handle function
    const handleRoute = (path) => {
        window.scrollTo(0, 0);
        history.push(path);
    }

    useEffect(() => {
        // If their is no config in the local storage redirect automatically to Config page
        if ((!board || board.id === 0) && history.location.pathname !== '/preview' && history.location.pathname !== '/error')  {
            handleRoute('/config')
        }
    });

    useEffect(() => {

        if (inactivityEnabled) {
            const handleActivity = () => {
                setLastActivityTime(Date.now());
            };

            // Add event listenners to detect mouse movements, mouse clicks, and keyboard key press
            window.addEventListener('mousemove', handleActivity);
            window.addEventListener('mousedown', handleActivity);
            window.addEventListener('keydown', handleActivity);
            window.addEventListener('scroll', handleActivity);

            const intervalId = setInterval(() => {
                if (Date.now() - lastActivityTime > inactivityDurationTime * 60 * 1000) { //time duration redirection conversion
                    if (history.location.pathname !== '/config') {
                        getBoard(board.id);
                        window.scrollTo(0, 0);
                        history.push('/');
                    }
                }
            }, 1000);

            return () => {
                clearInterval(intervalId);
                window.removeEventListener('mousemove', handleActivity);
                window.removeEventListener('mousedown', handleActivity);
                window.removeEventListener('keydown', handleActivity);
                window.removeEventListener('scroll', handleActivity);
            };
        }
    }, [inactivityDurationTime, history, lastActivityTime]);


    const handleOnClickSearch = (block) => {
        setSearchBlock(block)
        switch (block.displayMode) {
            case 'grid':
                handleRoute('/searchGrid/' + block.id)
                break;
            case 'alphabetic_list':
                handleRoute('/searchAlphabetical/' + block.id)
                break;
            case 'research':
                if (block.searchType === 'eposter_title') {
                    handleRoute('/searchTitle/' + block.id)
                } else {
                    handleRoute('/searchResearch/' + block.id)
                }

                break;
            default:
                if (block.type === 'link') {
                    setLink(block.btnLink)
                    setShowLink(true)
                }
                break;
        }
    }

    const handleOnClickGrid = (button, titleBlock) => {
        handleRoute(
            '/searchList/titleBlock/'
            + encodeURIComponent(titleBlock.replace(/<\/?[^>]+(>|$)/g, "")) + '/title/'
            + ((button.name === '') ? 'No title' : encodeURIComponent(button.name.replace(/<\/?[^>]+(>|$)/g, "")))
            + '/type/' + (button.type)
            + '/id/' + button.id
        )
    }

    const handleOnClickAlphabetical = (alphabeticalObject, titleBlock) => {
        handleRoute(
            '/searchList/titleBlock/'
            + encodeURIComponent(titleBlock.replace(/<\/?[^>]+(>|$)/g, ""))
            + '/title/' + encodeURIComponent(alphabeticalObject.name.replace(/<\/?[^>]+(>|$)/g, ""))
            + '/type/' + encodeURIComponent((alphabeticalObject.type === '') ? 'No title' : alphabeticalObject.type)
            + '/id/' + alphabeticalObject.id
        )
    }

    const handleSetShow = (show) => {
        setShowLink(show)
    }

    const handleSearchResearchSessionClick = (sessionRef, blockTitle) => {
            handleRoute('/searchInterventions/' + sessionRef +
                '/blockTitle/' + blockTitle)
    }

    useEffect(() => {
        if (parseInt(error.code) !== 0) {
            window.scrollTo(0, 0);
            history.push('/error');
        }
    }, [error, history])

    const handleResetError = () => {
        resetError()
        handleRoute('/')
    }

    useEffect(() => {
        if(css && css !== '') {
            const addStyle = (() => {
                const style = document.createElement('style');
                document.head.append(style);
                return (styleString) => style.textContent = styleString;
            })();
            addStyle(css)
        }
    }, [css])

    return (
        <div id='body-div' className={'defaultFontColor ePosterBoard' + board.id}>
            <div>
                <ModalLink show={showLink} setShow={(show) => handleSetShow(show)} url={link}/>
                <Switch>
                    <Route exact path='/config'>
                        <Config/>
                    </Route>
                    <Route exact path='/searchGrid/:id'>
                        <SearchGrid block={searchBlock}
                                    metaData={metaData}
                                    onButtonGridClick={(button, titleBlock) => handleOnClickGrid(button, titleBlock)}/>
                    </Route>
                    <Route exact path='/searchAlphabetical/:id'>
                        <SearchAlphabetical block={searchBlock}
                                            metaData={metaData}
                                            board={board}
                                            onClickAlphabetical={(alphabeticalObject, titleBlock) => handleOnClickAlphabetical(alphabeticalObject, titleBlock)}/>
                    </Route>
                    <Route exact path='/searchList/titleBlock/:titleBlock/title/:title/type/:type/id/:id'>
                        <SearchList block={searchBlock} metaData={metaData} board={board}/>
                    </Route>
                    <Route exact path='/searchResearch/:id'>
                        <SearchResearch block={searchBlock} metaData={metaData} onSessionClick={handleSearchResearchSessionClick} />
                    </Route>
                    <Route exact path='/searchTitle/:id'>
                        <SearchTitle block={searchBlock} metaData={metaData} board={board} />
                    </Route>
                    <Route exact path='/e-poster/:id/:title'>
                        <EPoster metaData={metaData}/>
                    </Route>
                    <Route exact path='/error'>
                        <Error error={error}
                               metaData={metaData}
                               onClick={() => handleResetError()}/>
                    </Route>
                    <Route exact path='/preview'>
                        <Preview />
                    </Route>
                    <Route exact path='/searchInterventions/:sessionRef/blockTitle/:blockTitle'>
                        <SearchInterventions board={board} metaData={metaData} />
                    </Route>
                    <Route render={function () {
                        return <Home onClick={(block) => handleOnClickSearch(block)}/>
                    }}/>
                </Switch>
            </div>
        </div>
    );
}

App.defaultProps = {
    error: {
        code: 0,
        text: ''
    },
    metaData: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        defaultColors: [
            'lightblue',
            'grey',
            'black'
        ]
    },
    board: {}
}

const mapStateToProps = state => {
    return ({
        error: state.error,
        metaData: {
            gridTemplateColumns: state.boards.board.display === 'landscape' ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr',
            defaultColors: [
                state.boards.board.primaryColor,
                state.boards.board.secondaryColor,
                state.boards.board.ternaryColor,
            ]
        },
        board: state.boards.board,
        css: state.css.css
    });
};

export default connect(mapStateToProps, {resetError, getBoard, getConfig})(App)

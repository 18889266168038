import axios from "axios";
import {
    ADD_CONTAINER_VIEW,
    ADD_MEDIA_PDF_VIEW,
    ADD_MEDIA_VDO_VIEW,
    ERROR_DEFAULT,
    ERROR_REFRESH_TOKEN_CODE,
    TOKEN_DURABILITY_SECURITY
} from "./types";
import {setAuthenticationState} from "./actions/authenticateAction";


export const refreshToken = (url, refreshToken) => {
    return axios({
        method: 'POST',
        url: url,
        headers: {'refresh-token': refreshToken},
        data: {}
    })
        .then((res) => {
            return {
                code: res.status,
                data: res.data.data,
            }
        })
        .catch((error) => {
            let statusCode = 0;
            let errorText = 'none';

            if (error.response !== undefined && error.response.data && error.response.data.errors) {
                statusCode = error.response.data.errors.status;
                errorText = error.response.data.errors.title;
            }

            return {
                code: statusCode,
                errors: {
                    'text': errorText,
                },
            }
        });
};

export const getAuthentication = async (apiUrl, grantAccessToken) => {
    const url = apiUrl + 'api/oauth/grant-authorization';
    return axios({
        method: 'POST',
        url: url,
        headers: {'grant-access': grantAccessToken},
        data: {}
    })
        .then((res) => {
            return {
                code: res.status,
                data: res.data.data,
            }
        })
        .catch((error) => {
            let statusCode = 403;
            let errorText = 'Can\'t authenticate. Wrong connection set.';

            if (error.response !== undefined && error.response.data && error.response.data.errors) {
                statusCode = error.response.data.errors.status;
                errorText = error.response.data.errors.title;
            }

            return {
                code: statusCode,
                errors: {
                    'text': errorText,
                },
            }
        });
}

export const call = async (method, url, headers, data, state, type, checkValidityOfToken = true) => async (dispatch) => {
    const urlRefresh = state.authenticate.url + 'api/oauth/refresh-token';
    let header = {...state.authenticate.headerQueries, ...headers}

    if (checkValidityOfToken === true && state.authenticate) {
        const dateExpirationToken = new Date(state.authenticate.expiresIn * 1000);
        const now = new Date();
        const diffInSecond = (dateExpirationToken.getTime() - now.getTime()) / 1000;

        if (diffInSecond <= TOKEN_DURABILITY_SECURITY) {
            let newAuth = await refreshToken(urlRefresh, state.authenticate.refreshToken);
            if (newAuth.errors === undefined) {
                dispatch(setAuthenticationState(state.authenticate.url, state.authenticate.grantAccessToken, newAuth.data));
                header['access-token'] = newAuth.data.tokenType + ' ' + newAuth.data.accessToken;
            }
        }
    }
    let response = await axios({
        method: method,
        url: url,
        headers: header,
        data: data
    })
        .then((res) => {
            return {
                code: res.status,
                data: res.data.data,
            }
        })
        .catch((error) => {
            let statusCode = 0;
            let errorText = 'none';

            if (error.response !== undefined && error.response.data && error.response.data.errors) {
                statusCode = error.response.data.errors.status;
                errorText = error.response.data.errors.title;
            }

            if (statusCode !== ERROR_REFRESH_TOKEN_CODE && statusCode !== 0) {
                switch (type) {
                    case ADD_CONTAINER_VIEW:
                    case ADD_MEDIA_PDF_VIEW:
                    case ADD_MEDIA_VDO_VIEW:
                        break;
                    default:
                        dispatch({
                            type: ERROR_DEFAULT,
                            payload: {
                                code: statusCode,
                                text: errorText,
                            },
                        });

                        return false;
                }
            }

            return {
                code: statusCode,
                errors: {
                    'text': errorText,
                },
            }
        });

    if (response.errors !== undefined && response.code === ERROR_REFRESH_TOKEN_CODE) {
        let newAuth = await refreshToken(urlRefresh, state.authenticate.refreshToken);

        if (newAuth.errors === undefined) {
            dispatch(setAuthenticationState(state.authenticate.url, state.authenticate.grantAccessToken, newAuth.data));
            header['access-token'] = newAuth.data.tokenType + ' ' + newAuth.data.accessToken;

            return await (await call(method, url, header, data, state, type, false))(dispatch);
        }
    }

    return response;
}
import {GET_GRID_SEARCH_LIST, GET_ALPHABETICAL_SEARCH_LIST} from '../types';

const initialState = {
    buttons: [],
    alphabeticalList: []
};

export default function foo(state = initialState, action) {
    switch (action.type) {
        case GET_GRID_SEARCH_LIST:
            return {
                ...state,
                buttons: action.payload,
            }
        case GET_ALPHABETICAL_SEARCH_LIST:
            return {
                ...state,
                alphabeticalList: action.payload,
            }
        default:
            return state;
    }
}
import './SearchBlock.css'
import {useHistory} from "react-router-dom"
import StarRating from "react-svg-star-rating";
import {connect} from "react-redux";
import {addContainerView} from "../redux/actions/ePosterAction";
import { enumSearchType } from '../config/search-configuration';

const SearchBlock = ({ePoster, searchBlockName, metaData, addContainerView, searchType, board, translations}) => {
    const history = useHistory();
    const handleClick = async (id, searchBlockName) => {
        await addContainerView(id);
        history.push('/e-poster/' + id + '/' + encodeURIComponent(searchBlockName.replace(/<\/?[^>]+(>|$)/g, "")));
    };
    
    return (
        <>
            <div onClick={() => handleClick(ePoster.id, searchBlockName)} className='d-flex flex-row  align-items-center mb-4 ms-4 me-4 p-2 pb-4 searchBlock'>
                <div>
                    <div className='d-flex flex-row justify-content-center align-items-center replayButton'>
                        <i className='fas fa-play colorWhite'/>
                    </div>
                </div>
                <div className='d-flex flex-column ps-3 py-2  subSearchBlock'>
                    <div className='mb-2'
                         style={{display : (
                                 (
                                     searchType
                                     && searchType !== 'session_reference'
                                     && searchType !== 'eposter_title'
                                     && searchType !== 'facultyName'
                                     && searchType !== enumSearchType.INTERVENTION
                                 )
                                     ? 'block'
                                     : 'none'
                             )}}>
                        <i>{ePoster[searchType]}</i>
                    </div>
                    {searchType !== enumSearchType.INTERVENTION && <div><strong>{ePoster.session_reference}</strong></div>}
                    <div className='ePosterTitle mt-2'>{ePoster.eposter_title}</div>
                    <div className='d-flex flex-row pt-2 align-items-center'>
                        <div className='me-auto'>
                            <div className='p-1 ps-3 pe-3 authorBlock mt-2' style={{backgroundColor: metaData.defaultColors[0], color: metaData.defaultColors[2]}}>
                                {translations['EPB_E_POSTER_BY']  ?? 'EPB_E_POSTER_BY'} <strong style={{textDecoration: 'underline'}}>{ePoster.facultyName}</strong>
                            </div>
                        </div>
                        <div className="ratingSection" style={{display: board.isRating ? 'block' : 'none'}}>
                            <StarRating
                                unit="float"
                                size={35}
                                innerRadius={25}
                                outerRadius={50}
                                activeColor={metaData.defaultColors[0]}
                                initialRating={(ePoster.rating !== null ? ePoster.rating : 0)}
                                isReadOnly={true}
                            />
                        </div>
                        <div 
                            className='p-1 ps-3 pe-3 ms-2 me-5 viewBlock'
                            style={{backgroundColor: metaData.defaultColors[0], color: metaData.defaultColors[2]}}>
                            {ePoster.nbView} {translations['EPB_E_POSTER_VIEWS']  ?? 'EPB_E_POSTER_VIEWS'}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

SearchBlock.defaultProps = {
    metaData: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        defaultColors: [
            'lightblue',
            'grey',
            'black'
        ]
    },
    searchType: undefined
}

const mapStateToProps = (state, ownProps) => {
    return ownProps;
};

export default connect(mapStateToProps, {addContainerView})(SearchBlock)
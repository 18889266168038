import Row from "./Row";
import {connect} from "react-redux";
import {getBlockList} from "../../redux/actions/blockAction";
import {useEffect, useState} from "react";
import Header from "./Header";
import Hr from "../../components/Hr";
import PropTypes from "prop-types";
import {getEPosterList} from "../../redux/actions/ePosterAction";
import Language from "../../components/Language";
import {getConfig} from "../../redux/actions/configAction";
import './Home.css'

const Home = ({bannerUrl, rows, currentLanguage, onClick, getBlockList, getEPosterList, getConfig, board}) => {
    const [useOnce, setUseOnce] = useState(true);

    useEffect( () => {
        if(useOnce && parseInt(board.id) !== 0) {
            getBlockList()
            getEPosterList()
            setUseOnce(false)
        }
    }, [getBlockList, getEPosterList, useOnce])

    const handleClickLanguage = (lang) => {
        getConfig(true, lang)
    }

    return (
        <div className='p-5'>
            <Header bannerUrl={bannerUrl} />
            <div id='home-language-dropdown'>
                <Language currentLanguage={currentLanguage} onClickLanguage={(language) => handleClickLanguage(language)} />
            </div>
            <div className='mt-5'>
                <Hr />
            </div>
            <div className='mt-5' />
            {rows.map((rows, i) => {
                return (
                    <Row key={'row-' + i}
                         onClick={(block) => onClick(block)}
                         gridTemplateColumns={rows.gridTemplateColumns}
                         blocks={rows.blocks}
                    />
                )
            })}
        </div>
    )
}

Home.defaultProps = {
    rows: [],
    bannerUrl: '',
}

Home.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return ({
        rows: state.block.blocks,
        bannerUrl: state.boards.board.bannerUrl,
        currentLanguage: state.translation.currentLanguage,
        board: state.boards.board,
    })
};

export default connect(mapStateToProps, {getBlockList, getEPosterList, getConfig})(Home)
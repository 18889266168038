import './Steps.css'

const Steps = ({nbStep, currentStep}) => {
    return (
        <div style={{textAlign: 'center', marginTop:'40px'}}>
            {[...Array(nbStep).keys()].map(i => (
                <span key={i}
                      className=
                          {
                            'step '
                            + ((i === (currentStep - 1)) ? 'active' : '')
                            + ((i < (currentStep - 1)) ? 'finish' : '')
                          } />
            ))}
        </div>
    );
}

export default Steps;

//Board
export const GET_BOARD_LIST = 'GET_BOARD_LIST'

//Config
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED'
export const AUTHENTICATION_RESET_STATUS = 'AUTHENTICATION_RESET_STATUS'
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS'
export const RESET_AUTHENTICATE_ERROR = 'RESET_AUTHENTICATE_ERROR'
export const AUTHENTICATE_PREVIEW_REDIRECT = 'AUTHENTICATE_PREVIEW_REDIRECT'
export const AUTHENTICATED = 'AUTHENTICATED'
export const GET_BOARD_INFO = 'GET_BOARD_INFO'
export const GET_BOARD = 'GET_BOARD'

//BLOCK
export const GET_BLOCK_LIST = 'GET_BLOCK_LIST'
export const GET_BLOCK = 'GET_BLOCK'

//FILTER SEARCH
export const GET_GRID_SEARCH_LIST = 'GET_GRID_SEARCH_LIST'
export const GET_ALPHABETICAL_SEARCH_LIST = 'GET_ALPHABETICAL_SEARCH_LIST'

//E_POSTER
export const GET_E_POSTER_LIST = 'GET_EPOSTER_LIST'
export const GET_E_POSTER = 'GET_E_POSTER'
export const ADD_RATING = 'ADD_RATING'
export const ADD_COMMENT = 'ADD_COMMENT'
export const ADD_MAIL = 'ADD_MAIL'
export const E_POSTER_RATING_SUCCESS = 'E_POSTER_RATING_SUCCESS'
export const E_POSTER_COMMENT_SUCCESS = 'E_POSTER_COMMENT_SUCCESS'
export const E_POSTER_MAIL_SUCCESS = 'E_POSTER_MAIL_SUCCESS'
export const E_POSTER_DEFAULT = 'E_POSTER_DEFAULT'
export const ADD_CONTAINER_VIEW = 'ADD_CONTAINER_VIEW'
export const ADD_MEDIA_PDF_VIEW = 'ADD_MEDIA_PDF_VIEW'
export const ADD_MEDIA_VDO_VIEW = 'ADD_MEDIA_VDO_VIEW'

//API
export const ERROR_REFRESH_TOKEN_CODE = 403;
export const HTTP_CODE_CREATED = 201;
export const TOKEN_DURABILITY_SECURITY = 60; //In sec
export const SEARCH_TYPE_ALPHABETIC_LIST = 'alphabetic_list';
export const SEARCH_TYPE_GRID = 'grid';
export const SOURCE_STAT_E_POSTER = 'eposter';

//ERROR
export const ERROR_DEFAULT = 'ERROR_DEFAULT';
export const ERROR_RESET = 'ERROR_RESET';

//CONFIG
export const GET_CONFIG = 'GET_CONFIG';

//TRANSLATION
export const GET_TRANSLATION = 'GET_TRANSLATION';

//CSS
export const GET_CSS = 'GET_CSS'

import {
    AUTHENTICATED,
    AUTHENTICATION_SUCCESS,
    AUTHENTICATION_FAILED,
    AUTHENTICATION_RESET_STATUS,
    AUTHENTICATE_PREVIEW_REDIRECT,
    RESET_AUTHENTICATE_ERROR
} from '../types';

const initialState = {
    url: '',
    grantAccessToken: '',
    accessToken: '',
    refreshToken: '',
    headerQueries: {},
    expiresIn: null,
    isAuthenticate: 0,
    isRedirectPreview: false,
    errors: {}
};

export default function foo(state = initialState, action) {
    switch (action.type) {
        case AUTHENTICATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isAuthenticate: 1
            };
        case AUTHENTICATION_FAILED:
            return {
                ...state,
                isAuthenticate: 2,
                errors: action.payload
            };
        case AUTHENTICATED:
            return {
                ...state,
                isAuthenticate: 0
            };
        case RESET_AUTHENTICATE_ERROR:
            return {
                ...state,
                isAuthenticate: 0
            };
        case AUTHENTICATE_PREVIEW_REDIRECT:
            return {
                ...state,
                isRedirectPreview : action.payload
            }
        case AUTHENTICATION_RESET_STATUS:
            return {
                ...state,
                isAuthenticate: 0
            }
        default:
            return state;
    }
}
import {GET_TRANSLATION} from "../types";

const initialState = {
    translations: [],
    currentLanguage: {
        id: 2,
        code: 'GB',
        name: 'EN'
    }
};

export default function foo(state = initialState, action) {
    switch (action.type) {
        case GET_TRANSLATION:
            if(action.payload.currentLanguage) {
                return {
                    ...state,
                    translations: action.payload.translations,
                    currentLanguage: action.payload.currentLanguage
                }
            } else {
                return {
                    ...state,
                    translations: action.payload.translations
                }
            }
        default:
            return state;
    }
}
import {Dropdown} from "react-bootstrap";
import './Language.css'
import { useSelector} from 'react-redux'

import LanguageConfig from '../configuration/language.js'

const Language = ({currentLanguage, onClickLanguage}) => {

    const {isLanguageSelector, languages} = useSelector((state) => {
        if(
            !state ||
            !state.boards ||
            !state.boards.board ||
            !state.boards.board.languageSelectorIds ||
            state.boards.board.languageSelectorIds.length === 0
        ) {
            return {
                isLanguageSelector: false,
                languages: []
            }
        }
        const board = state.boards.board;

        const languages = LanguageConfig.languages.filter(language => {
            return board.languageSelectorIds.includes(language.id + '')
        })

        return {
            isLanguageSelector: state.boards.board.isLanguageSelector,
            languages
        }
    })

    
    return (
        <>
        {isLanguageSelector &&
            <Dropdown>
                <Dropdown.Toggle id="dropdown-lang">
                    <div className='d-flex align-items-center justify-content-center languageFont'>
                        <div>&nbsp;{currentLanguage.name}</div>
                        <div className='ms-3 mb-1'><i className="fas fa-sort-down" /></div>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className='dropdownMenuLang'>
                    {languages.map((language) => {
                        return (
                        <Dropdown.Item key={language.id} onClick={() => onClickLanguage(language)}>
                            <div className='d-flex align-items-center justify-content-center'>
                                <div>&nbsp;{language.name}</div>
                            </div>
                        </Dropdown.Item>
                        )
                    })}
                    
                </Dropdown.Menu>
            </Dropdown>
        }
        </>
    );
}
Language.defaultProps = {
    size: '1em'
}

export default Language;

import {
    AUTHENTICATION_SUCCESS,
    AUTHENTICATION_FAILED,
    AUTHENTICATED,
    AUTHENTICATION_RESET_STATUS
} from '../types';
import {getAuthentication} from '../api';

export const clearStore = () => dispatch => {
        dispatch({
            type: 'CLEAR_STORE',
            payload: {},
        });
}

export const authentication = (apiUrl, token) => async dispatch => {
    if (apiUrl.slice(-1) !== '/') {
        apiUrl += '/';
    }

    let response = await getAuthentication(apiUrl, token);
    if (
        response.errors !== undefined
        && !response.data !== undefined
    ) {

        dispatch({
            type: AUTHENTICATION_FAILED,
            payload: response.errors
        })

        return false
    } else {
        dispatch({
            type: AUTHENTICATION_SUCCESS,
            payload:
                {
                    url: apiUrl,
                    grantAccessToken: token,
                    accessToken: response.data.tokenType + ' ' + response.data.accessToken,
                    refreshToken: response.data.tokenType + ' ' + response.data.refreshToken,
                    expiresIn: response.data.expiresIn,
                    headerQueries: {
                        'access-token' : response.data.tokenType + ' ' + response.data.accessToken,
                    },
                    isAuthenticate: 0
                }
        })
        return true
    }
};

export const authenticated = () => dispatch => {
    dispatch({
        type: AUTHENTICATED,
        payload: true
    })
};

export const setAuthenticationState = (url, token, oauthResponse) => {
    if (
        !oauthResponse
        || !oauthResponse.tokenType
    ) {
        return {
            type: AUTHENTICATION_FAILED,
            payload: false
        }
    } else {
        return {
            type: AUTHENTICATION_SUCCESS,
            payload: {
                url: url,
                grantAccessToken: token,
                accessToken: oauthResponse.tokenType + ' ' + oauthResponse.accessToken,
                refreshToken: oauthResponse.tokenType + ' ' + oauthResponse.refreshToken,
                expiresIn: oauthResponse.expiresIn,
                headerQueries: {
                    'access-token' : oauthResponse.tokenType + ' ' + oauthResponse.accessToken,
                },
                isAuthenticate: 0,
            }
        }
    }
}

export const setResetAuthenticateStatus = () => dispatch => {
    dispatch({
        type: AUTHENTICATION_RESET_STATUS,
        payload: false,
    });
}

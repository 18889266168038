const languages = [
    {
        id: 1,
        code: 'FR',
        name: 'FR',
    },
    {
        id: 2,
        code: 'GB',
        name: 'EN',
    },
    {
        id: 3,
        code: 'IT',
        name: 'IT',
    },
    {
        id: 4,
        code: 'DE',
        name: 'DE',
    },
    {
        id: 5,
        code: 'PT',
        name: 'PT',
    },
    {
        id: 6,
        code: 'ES',
        name: 'ES',
    },
]

const LanguageConfig = {
    languages
}

export default LanguageConfig;
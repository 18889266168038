import './NoMatchFound.css'

const NoMatchFound = ({text}) => {
    return (
        <div className='mt-5 ms-5'>
            <div className='d-flex justify-content-center'>
                <i className='noMatchFound mt-5'>{text}</i>
            </div>
        </div>
    );
}

export default NoMatchFound;

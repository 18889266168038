import {Modal} from "react-bootstrap";
import './ModalComment.css'
import {useState} from "react";
import {connect} from "react-redux";

const ModalComment = ({show, buttonColor, setShow, addComment, translations}) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const onChangeText = () => {
        document.getElementById('count-comment').innerHTML = (document.getElementById('text-area-comment').value.length + '/500')
        setIsButtonDisabled(document.getElementById('text-area-comment').value.length > 0)
    }

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)}
                   backdropClassName='modalCommentBackdrop'
                   dialogClassName="dialogModal modal-90w"
                   centered={true}>
                <Modal.Body>
                    <div className='d-flex align-items-center'>
                        <h4 className='me-auto' style={{color: 'white', textDecoration: 'underline'}}>{translations['EPB_COMMENT_TITLE']  ?? 'EPB_COMMENT_TITLE'}</h4>
                        <div onClick={() => setShow(false)}><i className="fas fa-2x fa-times-circle buttonStyle" style={{color: 'white'}}/></div>
                    </div>
                    <div className='mt-5 mx-3 textareaCommentSection'>
                        <textarea className="form-control textareaComment"
                                  id="text-area-comment"
                                  name="text"
                                  maxLength="500"
                                  placeholder={translations['EPB_COMMENT_PLACEHOLDER']  ?? 'EPB_COMMENT_PLACEHOLDER'}
                                  rows="12" onChange={onChangeText}/>
                        <div className='d-flex'>
                            <span className="ms-auto label label-default" id="count-comment">0/500</span>
                        </div>
                    </div>
                    <div className='d-flex mt-4'>
                        <div className='ms-auto'>
                            <div className={'buttonCommentSubmit p-2 px-4 me-4 ' + (isButtonDisabled ? '' : ' disableButtonComment')}
                                 onClick={() => addComment(document.getElementById('text-area-comment').value)}
                                 style={{backgroundColor: buttonColor}}
                            >
                                <strong>{translations['EPB_COMMENT_SUBMIT']  ?? 'EPB_COMMENT_SUBMIT'}</strong>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

ModalComment.defaultProps = {
    show: false,
    translations: {}
}

const mapStateToProps = state => {
    return {
        translations: state.translation.translations
    }
};

export default connect(mapStateToProps, {})(ModalComment)


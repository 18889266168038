import './SearchGridRow.css'
import ButtonGrid from "./ButtonGrid";
import {useEffect, useState} from "react";

const SearchGridRow = ({buttons, colors, onButtonGridClick, nbColumn}) => {
    const [txtColor, setTxtColor] = useState('black')
    const [bgColors, setBgColors] = useState(['white', 'white'])
    useEffect( () => {
        if(colors.length >= 3) {
            setTxtColor(colors[2])
            setBgColors([colors[0], colors[1]])
        }
    }, [colors])
    return (
        <>
            <div className={'m-2 row row-cols-' + nbColumn}>
                {
                    buttons.map((button, i) => {
                        return (
                            <div id={'button-grid-'+ button.id} key={i} className={'mb-4 col button-' + button.type}>
                                <ButtonGrid key={button.id}
                                            button={button}
                                            txtColor={txtColor}
                                            bgColor={bgColors[i % 2]}
                                            onButtonGridClick={onButtonGridClick}/>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

SearchGridRow.defaultProps = {
    buttons: [],
    gridTemplateColumns: '',
    nbColumn: 5,
    colors: [
        '',
        '',
        ''
    ]
}

export default SearchGridRow